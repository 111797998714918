import { getUserIdToken } from '../firebase';
import axios from 'axios';
import store from '../../store';
import {
  messageActioned,
  messageRead,
  messageTrashed
} from '../../shared-reducers/models';

export async function markRead(messageId) {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/messages`;
  const token = await getUserIdToken();
  try {
    const response = await axios.post(
      apiUrl,
      { messageId, operation: 'read' },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        },
        validateStatus: status => status === 200
      }
    );

    if (response.status !== 200) {
      const message = 'ERROR_MARKING_MESSAGE_READ';
      throw new Error(message);
    }
    // Update our local copy to reflect the server state
    store.dispatch(messageRead({ messageId }));

    return await response.data;
  } catch (error) {
    throw error;
  }
}

export async function markTrashed(messageId) {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/messages`;
  const token = await getUserIdToken();
  try {
    const response = await axios.post(
      apiUrl,
      { messageId, operation: 'trash' },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        },
        validateStatus: status => status === 200
      }
    );

    if (response.status !== 200) {
      const message = 'ERROR_MARKING_MESSAGE_TRASHED';
      throw new Error(message);
    }
    // Update our local copy to reflect the server state
    store.dispatch(messageTrashed({ messageId }));
  } catch (error) {
    throw error;
  }
}

export async function markActioned(messageId) {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/messages`;
  const token = await getUserIdToken();
  try {
    const response = await axios.post(
      apiUrl,
      { messageId, operation: 'action' },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        },
        validateStatus: status => status === 200
      }
    );

    if (response.status !== 200) {
      const message = 'ERROR_MARKING_MESSAGE_ACTIONED';
      throw new Error(message);
    }
    // Update our local copy to reflect the server state
    store.dispatch(messageActioned({ messageId }));

    return await response.data;
  } catch (error) {
    throw error;
  }
}
