import { auth, getUserIdToken } from '../firebase';
import getApiUrl from 'lib/utils/getApiUrl';

export const fetchLaunchDarklyFlags = async (featureKeys, customAttributes) => {
  const uri = _launchDarklyURI();
  const config = await _launchDarklyFetchConfig(featureKeys, customAttributes);
  const res = await window.fetch(uri, config);
  return res.json();
};
const apiUrl = getApiUrl();
const _launchDarklyURI = () => `${apiUrl}/features`;

const _launchDarklyFetchConfig = async (featureKeys, customAttributes) => {
  const headers = await _launchDarklyHeaders();
  const body = _launchDarklyBody(featureKeys, customAttributes);
  return {
    method: 'POST',
    headers,
    body
  };
};

const _launchDarklyHeaders = async () => {
  const token = await _currentUserToken();
  return {
    Authorization: `Bearer ${token}`,
    'content-type': 'application/json'
  };
};

const _launchDarklyBody = (featureKeys, customAttributes) => {
  const uid = auth.currentUser.uid;
  return JSON.stringify({
    featureKeys: _parseFeatureKeys(featureKeys),
    withDetails: true,
    userAttributes: {
      uid,
      ...customAttributes
    },
    customAttributes
  });
};

const _parseFeatureKeys = featureKeys => {
  if (Array.isArray(featureKeys)) {
    return featureKeys;
  }
  return [featureKeys];
};

const _currentUserToken = async () => {
  const token = await getUserIdToken();
  if (token) {
    return token;
  }
  throw new Error('Error getting user token for launch darkly');
};
