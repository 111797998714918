import { auth } from '../firebase';
import axios from 'axios';
import { blockIfAgent } from 'lib/utils';

export const aalInterest = blockIfAgent(
  async ({ clientName, clientEmail, cplusLoanId, opportunityId }) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/aalInterest`;

      const postVars = {
        clientName,
        clientEmail,
        cplusLoanId,
        opportunityId
      };

      const response = await axios.post(endpoint, postVars, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        },
        validateStatus: status => status < 500
      });

      if (response?.data?.success === false && response?.data?.error) {
        throw new Error(response?.data?.error);
      } else if (response?.status !== 200) {
        throw new Error('AAL_INTEREST_REQUEST_ERROR');
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export default aalInterest;
