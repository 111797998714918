import axios from 'axios';
import { getUserIdToken } from '../firebase';
import getApiUrl from 'lib/utils/getApiUrl';

export const getSuipLead = async config => {
  const uri = _buildApiURI(config.fdrApplicantId);
  let url = String(uri);
  const headers = await _buildHeaders();
  const { data } = await axios.get(`${url}`, {
    headers
  });

  if (!data) return null;
  return data;
};

export const _buildApiURI = fdrApplicantId => {
  const apiUrl = getApiUrl();
  return `${apiUrl}/lead/${fdrApplicantId}`;
};

const _buildHeaders = async () => {
  const headers = {
    'Content-type': 'application/json'
  };

  const token = (await getUserIdToken()) || '';
  headers['Authorization'] = `Bearer ${token}`;

  return headers;
};
