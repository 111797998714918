import axios from 'axios';
import { getUserIdToken } from '../firebase';
import { getLawFirmName } from 'lib/utils';
import getApiUrl from 'lib/utils/getApiUrl';

export const blogCategories = async ({ lawFirmId }) => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/content/blogCategories`;
  const token = await getUserIdToken();

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      validateStatus: status => {
        return status >= 200 && status < 500;
      },
      params: {
        brand: getLawFirmName(lawFirmId)
      }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export default blogCategories;
