import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logger } from 'shared-components';
// import { resetModels, updateModels } from 'shared-reducers/models';
import { updateModels } from 'shared-reducers/models';
import { updateUserRecord } from 'shared-reducers/userRecord';
import {
  modelsErrored,
  modelsLoaded,
  userRecordErrored,
  userRecordLoaded
} from 'shared-reducers/dataStatus';
import { useQuery } from 'react-query';
import {
  getAppData,
  getUserRecord as getUserRecordFromFirestore
} from 'lib/api';

const LOGGER_PREFIX = 'BOOTSTRAP :: useAppData ::';

export function useAppData() {
  const dispatch = useDispatch();

  const {
    refetch: refetchAppModels,
    isLoading: isLoadingModels,
    isError: isModelsError
  } = useQuery('appModels', getAppData, {
    enabled: false, // We must explicitly call this to load data
    staleTime: 2000, // Prevent refetches inside two seconds
    retry: 1,
    onSuccess: appModels => {
      dispatch(updateModels(appModels));
      dispatch(modelsLoaded());
    },
    onError: appModelsError => {
      logger.error(
        `${LOGGER_PREFIX} Error loading app models (${appModelsError.message})`
      );
      dispatch(modelsErrored());
    }
  });

  const { refetch: refetchUserRecord } = useQuery(
    'userRecord',
    getUserRecordFromFirestore,
    {
      enabled: false, // We must explicitly call this to load data
      staleTime: 2000, // Prevent refetches inside two seconds
      retry: 1,
      onSuccess: userRecord => {
        dispatch(updateUserRecord(userRecord));
        dispatch(userRecordLoaded());
      },
      onError: userRecordError => {
        logger.error(
          `${LOGGER_PREFIX} Error loading user record (${userRecordError.message})`
        );
        dispatch(userRecordErrored());
      }
    }
  );

  const refetchModels = useCallback(() => {
    refetchAppModels();
  }, [refetchAppModels]);

  const refetchUser = useCallback(() => {
    refetchUserRecord();
  }, [refetchUserRecord]);

  // Exporting these functions so that we can fetch/refectch data on demand
  return {
    fetchModels: refetchModels,
    isLoadingModels,
    isModelsError,
    fetchUserRecord: refetchUser
  };
}
