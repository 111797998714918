import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import 'styled-components/macro';
import { Composition, Box, useMediaQuery } from 'atomic-layout';
import { Helmet } from 'react-helmet-async';
import { useBrand } from 'lib/hooks';
import {
  LeadDocUploadNav,
  LeadDocUploadFooter,
  AchieveLeadDocFooter
} from 'shared-pages/common';
import { Layout, FallBackLoader, PageLayout, logger } from 'shared-components';
import {
  Heading,
  Text,
  Button,
  Icon,
  Loader,
  Panel,
  PanelTitle,
  PanelTitleHeading,
  Image
} from '@ffn/ui';
import {
  Doc,
  TakeAPhoto,
  Trash,
  CheckmarkCircleSolid,
  Alert2,
  MicrosoftWord,
  Excel,
  Pdf,
  DocumentVault
} from '@ffn/icons';
import { useDropzone } from 'react-dropzone';
import {
  leadDocUploadDocument,
  leadDocSendDcEmail,
  leadDocUploadSendNotification
} from 'lib/api/uploadDocument';

import { ErrorBoundary } from 'react-error-boundary';
import { useQueryParam } from 'lib/hooks';
import { useQuery } from 'react-query';
import { getLeadDocUploadUserByOobCode } from 'lib/api/getLeadDocUser';

const areas = `
  header
  main
  footer
`;
const uploadHref = window.location.href;

function ErrorFallback() {
  const brand = useBrand();

  return (
    <PageLayout style={{ textAlign: 'center' }}>
      <Panel data-testid="errorBoundaryMessage">
        <PanelTitle
          css={`
            margin-bottom: 12px !important;
          `}
        >
          <PanelTitleHeading>Page Error</PanelTitleHeading>
        </PanelTitle>
        <Heading as="h2" style={{ padding: '1rem 0' }}>
          We are sorry. An unexpected error occurred.
        </Heading>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            justifyContent: 'center'
          }}
        >
          <div style={{ paddingTop: '1rem' }}>
            <Button variant="tertiary" href={uploadHref}>
              <a
                style={{ textDecoration: 'none', color: 'inherit' }}
                href={uploadHref}
              >
                Return to Document Upload
              </a>
            </Button>
          </div>
          <div style={{ padding: '4rem 0 1rem 0' }}>
            <Image src={brand('logo.main')} width="97" />
          </div>
        </div>
      </Panel>
    </PageLayout>
  );
}

export function LeadDocUpload() {
  const brand = useBrand();
  const { oobCode } = useParams();
  const isAchieve = brand('business-name.abbr') === 'Achieve';
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const [files, setFiles] = useState([]);
  const [rejectFiles, setRejectFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [oobCodeInfo, setOobCodeInfo] = useState(null);
  const [notes, setNotes] = useState('');
  const [isExpiredCode, setIsExpiredCode] = useState(false);
  const params = useQueryParam();
  const docType = params.get('docType');
  const suipDebtAccountId = params.get('suip_debt_account_id');
  const suipConditionId = params.get('suip_condition_id');
  let docTypeName = params.get('docTypeName');
  //keeps only A-Za-z0-9._ -. everything else is converted to _
  docTypeName = docTypeName?.replace(/[^A-Za-z0-9._-]/g, '_');

  const LOGGING_PREFIX = `LEAD DOC UPLOAD ::`;

  const sanitizedOobCode = oobCode.trim();

  if (!sanitizedOobCode) {
    setIsExpiredCode(true);
  }

  // Calling our endpoint to validate the OOB code from the URL params
  // If it's valid, we'll set the OOB code
  // If it's invalid, we want to setIsExpiredCode, which will display
  // the invalid code UI and instruct the user to call in to CS.
  useQuery(
    ['getLeadDocUploadUserByOobCode'],
    () => getLeadDocUploadUserByOobCode({ oobCode: sanitizedOobCode }),
    {
      enabled: sanitizedOobCode !== '',
      onSuccess: data => {
        setOobCodeInfo(sanitizedOobCode);
      },
      onError: err => {
        logger.error(
          `${LOGGING_PREFIX} OOB Code invalid or expired (${sanitizedOobCode})`
        );
        setIsExpiredCode(true);
      },
      staleTime: Infinity,
      retry: 1
    }
  );

  /**
   * Callback function to handle accepted files dropped into the dropzone.
   * It renames the file object, adds metadata, and updates the files state.
   *
   * @param {Array} acceptedFiles - Array of accepted files dropped into the dropzone
   */
  const onDropAccepted = useCallback(
    acceptedFiles => {
      if (acceptedFiles) {
        const newFiles = acceptedFiles.map(fileObj => {
          // Extract the file extension from the original file name
          const fileExtension = fileObj.name.split('.').pop();
          const newName = `${docTypeName}.${fileExtension}`;

          // Rename the file object
          const newFileObj = new File([fileObj], newName, {
            type: fileObj.type
          });
          newFileObj.metadata = {
            document_type: docType,
            suip_debt_account_id: suipDebtAccountId,
            suip_condition_id: suipConditionId
          };

          return {
            fileObj: docTypeName ? newFileObj : fileObj,
            id: `${fileObj.name}_${Date.now()}`,
            uploaded: false,
            uploading: false,
            failed: false
          };
        });
        setFiles([...files, ...newFiles]);
      }
    },
    [files, docType, docTypeName, suipDebtAccountId, suipConditionId]
  );

  const onDropRejected = useCallback(
    rejectedFiles => {
      if (rejectedFiles) {
        setRejectFiles(rejectedFiles.map(file => file.file));
      }
    },
    [setRejectFiles]
  );

  const removeFile = file => {
    setFiles(files.filter(({ id }) => id !== file.id));
  };

  const clearFiles = () => {
    setFiles([]);
    setRejectFiles([]);
    setUploading(false);
    setUploaded(false);
  };

  // function uploads each file then sends 2 separate notifications to DC
  // the original is via an email through a firebase function, second is
  // via the gateway
  // Enhance error logging with more context
  const uploadFiles = async () => {
    try {
      await Promise.all(files.map(uploadFile));

      const uploadedFiles = files.filter(({ uploaded }) => uploaded);
      const fileNames =
        uploadedFiles.map(file => file.fileObj.name).join('\n') || '';

      if (uploadedFiles.length > 0) {
        // Ensure there are uploaded files before notifying
        const description = uploadedFiles
          .map(file => file.fileObj.name)
          .join(', ');
        let notificationPromises = [];
        notificationPromises.push(
          leadDocSendDcEmail({ oobCode: oobCode.trim(), notes, fileNames })
        );
        notificationPromises.push(
          leadDocUploadSendNotification(oobCode, description)
        );
        await Promise.allSettled(notificationPromises);
        setUploaded(true);
      } else {
        logger.warn(`${LOGGING_PREFIX} No files were uploaded successfully.`);
      }
    } catch (e) {
      logger.error(
        `${LOGGING_PREFIX} An error occurred during file upload or notification: ${e.message}`
      );
    } finally {
      setNotes(''); // Clear notes regardless of success or failure
    }
  };

  const setDocUploading = (file, isUploading) => {
    const tempFiles = [...files];
    files.forEach((f, i) => {
      if (f.id === file.id) {
        tempFiles[i].uploading = isUploading;
      }
    });
    setFiles([...tempFiles]);
  };

  const setDocUploaded = (file, isUploaded) => {
    const tempFiles = [...files];
    files.forEach((f, i) => {
      if (f.id === file.id) {
        tempFiles[i].uploaded = isUploaded;
      }
    });
    setFiles([...tempFiles]);
  };

  const setDocFailed = (file, isFailed) => {
    const tempFiles = [...files];
    files.forEach((f, i) => {
      if (f.id === file.id) {
        tempFiles[i].failed = isFailed;
      }
    });
    setFiles([...tempFiles]);
  };

  const uploadFile = async file => {
    try {
      setDocUploading(file, true);
      await leadDocUploadDocument(file, oobCode);
      setDocUploading(file, false);
      setDocUploaded(file, true);
    } catch (e) {
      logger.error(`${LOGGING_PREFIX} (${e.message})`);
      setDocUploading(file, false);
      setDocFailed(file, true);
    }
  };

  const fileIcon = fileName => {
    const extPieces = fileName.split('.');
    const ext = extPieces[extPieces.length - 1];
    switch (ext) {
      case 'pdf':
        return <Icon icon={Pdf} size={14} />;
      case 'doc':
      case 'docx':
        return <Icon icon={MicrosoftWord} size={14} />;
      case 'xls':
      case 'xlsx':
      case 'csv':
        return <Icon icon={Excel} size={14} />;
      default:
        return <Icon icon={DocumentVault} size={14} />;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple: true,
    maxSize: 1024 * 1024 * 128,
    disabled: uploading,
    accept: {
      'image/*': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/msword': [],
      'application/vnd.ms-powerpoint': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
      'application/pdf': [],
      'text/csv': [],
      'text/rft': [],
      'application/rtf': []
    }
  });
  const sizeErrors = rejectFiles.filter(file => file.size > 1024 * 1024 * 128);
  const typeErrors = rejectFiles.filter(file => file.size < 1024 * 1024 * 128);

  if (!oobCodeInfo && !isExpiredCode) {
    return <FallBackLoader isPage={true} />;
  }

  return (
    <Composition areas={areas} templateCols="1fr" templateRows="auto 1fr auto">
      {({ Header, Main, Footer }) => (
        <>
          <Helmet>
            <title>{brand('business-name.long')} Dashboard</title>
          </Helmet>
          <Header>
            <LeadDocUploadNav />
          </Header>
          <div
            css={`
              background-color: ${props => props.theme.colors.grayLight};
            `}
          >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Main>
                <Layout.BasicPage
                  title={<Heading>Securely Upload Documents</Heading>}
                >
                  {uploaded ? (
                    <Box
                      css={`
                        margin-left: auto;
                        margin-right: auto;
                        width: 300px;
                        text-align: center;
                      `}
                      marginVertical={16}
                    >
                      <Icon
                        icon={CheckmarkCircleSolid}
                        size={24}
                        css={`
                          color: ${props => props.theme.colors.tertiaryGreen};
                        `}
                      />
                      <Text>Success! Your files have been uploaded.</Text>
                      <Button variant="secondary" onClick={clearFiles}>
                        OK
                      </Button>
                    </Box>
                  ) : (
                    <>
                      {isExpiredCode ? (
                        <Text>
                          Sorry, your upload link has expired. Please request
                          another upload link from your Freedom Debt Relief
                          representative.
                        </Text>
                      ) : (
                        <>
                          <Box
                            justifyContent="center"
                            alignItems="center"
                            css={`
                              background-color: white;
                            `}
                            padding={16}
                          >
                            {sizeErrors.length > 0 && (
                              <Box>
                                <strong>
                                  The following document
                                  {sizeErrors.length > 1 ? 's' : ''} could not
                                  be added because{' '}
                                  {sizeErrors.length > 1 ? 'they' : 'it'} exceed
                                  {sizeErrors.length > 1 ? '' : 's'} the file
                                  size limit of 128 MB:
                                </strong>
                                <ul>
                                  {sizeErrors.map((item, i) => (
                                    <li key={i}>{item.name}</li>
                                  ))}
                                </ul>
                              </Box>
                            )}
                            {typeErrors.length > 0 && (
                              <Box>
                                <strong>
                                  The following document
                                  {typeErrors.length > 1
                                    ? 's have'
                                    : ' has an'}{' '}
                                  unacceptable file type
                                  {typeErrors.length > 1 ? 's' : ''}. Accepted
                                  File Types: xls, xlsx, csv, doc, docx, rtf,
                                  ppt, pptx, pdf, png, jpg, jpeg, gif, tiff,
                                  webp
                                </strong>
                                <ul>
                                  {typeErrors.map((item, i) => (
                                    <li key={i}>{item.name}</li>
                                  ))}
                                </ul>
                              </Box>
                            )}
                            <Box
                              flex
                              flexDirection="column"
                              height="100%"
                              justifyContent="center"
                              alignItems="center"
                              padding="0px"
                              paddingSm="0px 4%"
                              paddingMd="0px 12%"
                              paddingLg="0px 16%"
                              {...getRootProps({
                                className: 'Dropzone'
                              })}
                            >
                              <input {...getInputProps()} />
                              <Button
                                variant="primary"
                                height="40px"
                                padding="4px 8px"
                                onClick={e => e.preventDefault()}
                                rightEnhancer={
                                  <Icon
                                    icon={Doc}
                                    size={24}
                                    css={`
                                      margin-left: 8px;
                                      height: 100%;
                                    `}
                                  />
                                }
                              >
                                Browse
                              </Button>
                              <Text>or</Text>
                              {isSmallScreen ? (
                                <Button
                                  variant="primary"
                                  height="40px"
                                  padding="4px 8px"
                                  onClick={e => e.preventDefault()}
                                  rightEnhancer={
                                    <Icon
                                      icon={TakeAPhoto}
                                      size={24}
                                      css={`
                                        margin-left: 8px;
                                        height: 100%;
                                      `}
                                    />
                                  }
                                >
                                  Take Photo
                                </Button>
                              ) : (
                                <Text
                                  css={`
                                    margin-bottom: 0;
                                    text-align: center;
                                  `}
                                >
                                  Drag and drop files here to attach
                                </Text>
                              )}
                            </Box>
                          </Box>
                          <div
                            css={`
                              margin-bottom: 16px;
                            `}
                          >
                            128 MB file size limit
                          </div>
                          {files.length > 0 && (
                            <>
                              <hr />
                              <div
                                css={`
                                  margin-bottom: 16px;

                                  & > div:hover {
                                    background: ${props =>
                                      props.theme.colors.grayRegular};
                                  }
                                `}
                              >
                                {files.map((file, i) => {
                                  return (
                                    <Composition
                                      key={i}
                                      areas={`Extension Name Progress Removal`}
                                      templateCols="30px auto 30px 30px"
                                      templateRows="auto"
                                      gap={8}
                                      marginBottom={8}
                                      padding={4}
                                    >
                                      {({
                                        Extension,
                                        Name,
                                        Progress,
                                        Removal
                                      }) => (
                                        <>
                                          <Extension>
                                            {fileIcon(file.fileObj.name)}
                                          </Extension>
                                          <Name>
                                            {file.fileObj.name}
                                            <div
                                              css={`
                                                font-size: 12px;
                                                font-style: italic;
                                              `}
                                            >
                                              {!(
                                                file.uploading ||
                                                file.uploaded ||
                                                file.failed
                                              ) && 'Pending upload'}
                                              {file.uploading && 'Uploading...'}
                                              {file.uploaded && 'Uploaded'}
                                              {file.failed &&
                                                'Failed to upload'}
                                            </div>
                                          </Name>
                                          <Progress>
                                            {file.uploading && (
                                              <Loader
                                                size={16}
                                                css={`
                                                  margin-top: 2px;
                                                `}
                                              />
                                            )}
                                            {file.uploaded && (
                                              <Icon
                                                icon={CheckmarkCircleSolid}
                                                size={16}
                                                css={`
                                                  color: ${props =>
                                                    props.theme.colors
                                                      .tertiaryGreen};
                                                  margin-top: 2px;
                                                `}
                                              />
                                            )}
                                            {file.failed && (
                                              <Icon
                                                icon={Alert2}
                                                size={16}
                                                css={`
                                                  color: ${props =>
                                                    props.theme.colors
                                                      .secondaryRed};
                                                  margin-top: 2px;
                                                `}
                                              />
                                            )}
                                          </Progress>
                                          <Removal>
                                            {!(
                                              file.uploaded || file.uploading
                                            ) && (
                                              <Icon
                                                icon={Trash}
                                                size={16}
                                                title="Remove File"
                                                onClick={() => removeFile(file)}
                                                css={`
                                                  cursor: pointer;
                                                  color: ${props =>
                                                    props.theme.colors
                                                      .secondaryBlue};
                                                `}
                                              />
                                            )}
                                          </Removal>
                                        </>
                                      )}
                                    </Composition>
                                  );
                                })}
                              </div>
                            </>
                          )}
                          <div>
                            <label>Notes (Optional)</label>
                            <textarea
                              css={`
                                width: 100%;
                                height: 70;
                              `}
                              value={notes}
                              onChange={ev => setNotes(ev.target.value)}
                            />
                          </div>
                          <Button
                            variant="secondary"
                            onClick={() => uploadFiles()}
                            disabled={files.length < 1 || uploaded}
                          >
                            Upload Documents
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Layout.BasicPage>
              </Main>
            </ErrorBoundary>
          </div>
          <Footer>
            {isAchieve ? <AchieveLeadDocFooter /> : <LeadDocUploadFooter />}
          </Footer>
        </>
      )}
    </Composition>
  );
}
