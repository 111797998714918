import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@achieve/sunbeam';
import { useTranslation } from 'lib/hooks';
import { getPtS } from 'shared-selectors/userRecord';
import { getPtSData } from 'shared-selectors/pts';
import { analyticsHotjarEvent } from 'lib/utils/analytics';
import { logger } from 'shared-components';
import { EstimatedSettlements } from '../EstimatedSettlements';
// Renaming component here to reflect how we're using it
import { InformationCard as HeadingCard } from '../InformationCard';
import {
  ALL_POSSIBLE_CARD_TYPES,
  CORE_QUESTION_MAP,
  LOGGING_PREFIX
} from '../assets/constants';
import styles from './PathToSettlement.module.scss';
import { useQuery } from 'react-query';

/**
 * Selects and deduplicates core question identifiers based on the provided ptsData, while excluding dismissed cards.
 * This function combines infoCards and alertCards from ptsData, filters out any cards that have been permanently
 * dismissed as indicated in ptsFireStoreData, and then determines the relevant core questions based on the
 * remaining cards. If no estimated settlements are available, it defaults to a predefined set of questions.
 *
 * @param {Object} ptsData - The data object containing infoCards, alertCards, and possibly nextSettlements.
 * @param {Object} ptsFireStoreData - The data object that includes information about which cards have been
 *                                    permanently dismissed, helping to tailor the displayed content to user preferences.
 * @returns {Array} An array of unique core question keys relevant to the current data context.
 */

const selectCoreQuestions = ({
  missingAccountNumberOrDate,
  ptsData,
  ptsFireStoreData
}) => {
  // Return default questions if no estimated settlements are present
  if (!ptsData?.nextSettlements?.length || missingAccountNumberOrDate) {
    return ['how_do_you_decide_which_creditor', 'how_can_i_succeed'];
  }

  // all of the possible cards in play
  const combinedCards = [
    ...(ptsData.infoCards || []),
    ...(ptsData.alertCards || [])
  ];

  // get any dismissed cards
  const dismissedCards = Object.keys(
    ptsFireStoreData?.permanentlyDisableShowCards || {}
  );

  // remove dismissed cards from those in play
  const remainingCards = combinedCards.filter(
    card => !dismissedCards.includes(card?.type)
  );

  // Extract core questions from the remaining cards using the unified map
  const coreQuestionKeys = remainingCards.reduce((acc, card) => {
    const questions = CORE_QUESTION_MAP[card.type] || [];
    return acc.concat(questions);
  }, []);

  if (ptsData?.nextSettlements?.length > 0) {
    coreQuestionKeys.push('how_do_you_decide_which_creditor');
  }

  // Deduplicate core questions
  return Array.from(new Set(coreQuestionKeys));
};

// returns true if a settlement is missing either account number or estimated settlement range or if the estimated settlement range is wrong
const hasMissingSettlementInfo = nextSettlements => {
  // Check each settlement for missing account number or improperly formatted estimated settlement date
  return nextSettlements.some(
    settlement =>
      !settlement.accountNumber ||
      !settlement.estimatedSettlementRange || // Check if date exists
      !settlement.estimatedSettlementRange.estStart // Check if we have a start date and end date could be null
  );
};

// This renders the PTS heading and is the parent for all PTS components
function PtSLayoutContainer({ children }) {
  const { t } = useTranslation();

  return (
    <section
      className={styles.pathToSettlementBorder}
      style={{ padding: '32px 0' }}
    >
      <Box className={styles.pathToSettlementHeading} paddingBottom="24px">
        <Typography
          variant="ascendHeadingMd"
          data-testid="pts-path-to-settlement-heading"
          component="h2"
        >
          {t('pts.yourSettlementSnapshot')}
        </Typography>
      </Box>
      <div className={styles.pathToSettlementContent}>{children}</div>
    </section>
  );
}

export const PathToSettlement = () => {
  const ptsFireStoreData = useSelector(getPtS);
  const [activeCoreQuestions, setActiveCoreQuestions] = useState([]);
  const [missingAccountNumberOrDate, setMissingAccountNumberOrDate] = useState(
    false
  );
  const [activeAlertCards, setActiveAlertCards] = useState([]);
  const [activeInfoCards, setActiveInfoCards] = useState([]);

  const ptsData = useSelector(getPtSData);

  // validates the settlement data when ptsData is available
  useEffect(() => {
    if (
      ptsData &&
      Array.isArray(ptsData.nextSettlements) &&
      ptsData?.nextSettlements.length
    ) {
      const isMissingInfo = hasMissingSettlementInfo(ptsData.nextSettlements);

      if (isMissingInfo) {
        logger.warn(
          `${LOGGING_PREFIX} An estimated settlement is missing date or account number data`
        );
      }

      setMissingAccountNumberOrDate(isMissingInfo);
    }
  }, [ptsData]);

  useEffect(() => {
    if (ptsData) {
      const activeCoreQuestions = selectCoreQuestions({
        missingAccountNumberOrDate,
        ptsData,
        ptsFireStoreData
      });
      setActiveCoreQuestions(activeCoreQuestions, missingAccountNumberOrDate);
    }
  }, [missingAccountNumberOrDate, ptsData, ptsFireStoreData]);

  useEffect(() =>
    // sets the active alert and info cards
    // removes any possible cards the backend may send but
    // the front end does not support
    {
      if (ptsData?.alertCards) {
        setActiveAlertCards(
          ptsData.alertCards.filter(card =>
            ALL_POSSIBLE_CARD_TYPES?.includes(card?.type)
          )
        );
      }
      if (ptsData?.infoCards) {
        setActiveInfoCards(
          ptsData.infoCards.filter(card =>
            ALL_POSSIBLE_CARD_TYPES?.includes(card?.type)
          )
        );
      }
    }, [ptsData]);

  useQuery(
    ['hotjarEventPTS', ptsData],
    () => analyticsHotjarEvent('PtS_presented'),
    { enabled: !!ptsData, staleTime: Infinity, retry: 1 }
  );

  if (ptsData) {
    return (
      //*** WE MAY NEED TO CHECK THE USER RECORD pts FIELD TO MAKE SURE IT HASN'T BEEN PERMANENTLY DISMISSED */
      <PtSLayoutContainer>
        {ptsData.mainCard ? (
          <div className={styles.headingCard}>
            <HeadingCard
              type={ptsData.mainCard.type}
              metadata={ptsData.mainCard.meta}
            />
          </div>
        ) : null}
        <EstimatedSettlements
          activeAlertCards={activeAlertCards}
          activeInfoCards={activeInfoCards}
          nextSettlements={ptsData?.nextSettlements}
          activeCoreQuestions={activeCoreQuestions}
          missingAccountNumberOrDate={missingAccountNumberOrDate}
        />
        {/*** FAQs can go here in the future */}
      </PtSLayoutContainer>
    );
  }

  return null;
};
