import { getUserIdToken } from '../firebase';
import axios from 'axios';
import { blockIfAgent } from 'lib/utils';

export const updateClientInfo = blockIfAgent(
  async ({
    mailingStreet,
    mailingState,
    mailingPostalCode,
    mailingCity,
    phone,
    mobilePhone,
    clientEmail2,
    settlementAuthSMS,
    transactionalSMS,
    marketingSMS
  }) => {
    const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/updateClientInfo`;
    const token = await getUserIdToken();
    try {
      const response = await axios.put(
        apiUrl,
        {
          mailingStreet,
          mailingState,
          mailingPostalCode,
          mailingCity,
          phone,
          mobilePhone,
          clientEmail2: clientEmail2?.toLowerCase() || '',
          settlementAuthSMS,
          transactionalSMS,
          marketingSMS
        },
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          }
        }
      );
      return response;
    } catch (e) {
      throw e;
    }
  }
);

export default updateClientInfo;
