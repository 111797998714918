import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth as useFirebaseAuth } from 'lib/hooks/useAuth';

export function useUnifiedSignout() {
  const auth0 = useAuth0();
  const firebase = useFirebaseAuth();
  /** Sign out user on both Firebase and Auth0 */
  const unifiedSignout = useCallback(
    (returnToPath = '') => {
      // validates the returnToPath is a string in case
      // signout is passed directly to an onClick handler
      // when this happens signout receives an event object, not a string
      if (typeof returnToPath !== 'string') {
        returnToPath = '';
      }
      // the path the user will be forwarded to after signing out
      const returnTo = returnToPath
        ? window.location.origin + returnToPath
        : undefined;
      // since genesys is in global scope for fdr. it will be true and executes the logout command.
      if (typeof Genesys === 'function') {
        // eslint-disable-next-line no-undef
        Genesys('command', 'Auth.logout');
      }
      firebase
        .signout()
        .then(() => {
          return auth0.logout({
            returnTo
          });
        })
        .catch(e => {
          console.error('Error signing out of Firebase', e);
          window.location.reload();
        });
    },
    // Only want to create this function once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { signout: unifiedSignout };
}
