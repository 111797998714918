import getApiUrl from 'lib/utils/getApiUrl';
import { getUserIdToken } from '../firebase';

/**
 * Retrieves the password reset URL for a given email and law firm ID.
 * @function
 * @async
 * @returns {Promise<Object>} A promise that resolves to an object containing the password reset URL.
 * @throws {Object} Throws an error object if the response status is not 200.
 */

export const requestPasswordResetEmail = async () => {
  const urlStem = getApiUrl();
  const url = `${urlStem}/password-reset`;

  const token = await getUserIdToken();

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    validateStatus: status => {
      return status >= 200 && status < 500;
    }
  });

  if (response.status !== 200) {
    throw new Error(response.status);
  }

  return;
};

export default requestPasswordResetEmail;
