import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useBrandAwareNavigation, useUnifiedAuth } from 'lib/hooks';
import FallBackLoader from '../FallBackLoader';

/***
 * All the mechanics required to make sure a user is authenticated with
 * either Auth0 or Firebase are included in this component. If the user is authed,
 * we render the Outlet (child routes). If not, we redirect to the login page, preserving
 * the user's intended destination.
 */
function RouteGuard({
  isAuthenticatedCallback,
  wrapper: Wrapper,
  isDashboard
}) {
  const authCallback = useMemo(() => isAuthenticatedCallback, [
    isAuthenticatedCallback
  ]);
  const {
    isAuthenticated,
    error,
    hasAuth0Token,
    redirectToBrandSpecificLogin
  } = useUnifiedAuth(authCallback);
  const assignBrandAwareRoute = useBrandAwareNavigation();

  // Redirect to the login error page if there's an error thrown in useUnifiedAuth
  if (error) {
    console.warn('ROUTE GUARD :: An error occurred while checking auth status');
    console.error(error);
    assignBrandAwareRoute('/login-error');
  }

  if (isAuthenticated) {
    console.info('ROUTE GUARD :: User is authenticated');
    if (!Wrapper) return <Outlet />;

    // This allows all child routes to be rendered properly using React Router v6
    // An unauthenticated user is redirected in the `useEffect` above
    return (
      <Wrapper renderTourProvider={isDashboard}>
        <Outlet />
      </Wrapper>
    );
  } else {
    // If we don't have an Auth0 token, redirect user to login page
    if (hasAuth0Token === false) {
      redirectToBrandSpecificLogin();
    }
    // If we're not sure about authentication status, we'll render a loading screen
    console.info('ROUTE GUARD :: Fetching auth status');
    return <FallBackLoader isPage />;
  }
}

export { RouteGuard };
