import { getUserIdToken } from '../../firebase';
import axios from 'axios';
import { blockIfAgent } from 'lib/utils';

export const loadDocuSign = blockIfAgent(async ({ fullName, email, envelopeId, alertId, clientGuid }) => {
  try {
    const token = await getUserIdToken();
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/docusignUrl`;
    const response = await axios.post(
      endpoint,
      { fullName, email, envelopeId, alertId, clientGuid },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        }
      }
    );

    return response?.data?.url;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export default loadDocuSign;
