import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { Box, Card, Typography, useTheme } from '@achieve/sunbeam';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';

import { MugWithHeartSVG } from '../assets/MugSvg';
import { InformationCard } from '../InformationCard';
import { CoreQuestions } from '../CoreQuestions';
import { AlertCard } from '../AlertCard';

import styles from './EstimatedSettlements.module.scss';
import { getAbbrMonthOnlySettlementRange } from 'lib/utils';

//  Custom hook for firing and tracking component load analytics events once.
// accountId, enrolledBalance, and estimatedSettlementRange are optional
function useEstimatedSettlementCardLoadEvent({
  label,
  eventName,
  accountId,
  enrolledBalance,
  estimatedSettlementRange
}) {
  const [loadEventFired, setLoadEventFired] = useState(false);
  const abbrSettlementRange = getAbbrMonthOnlySettlementRange(
    estimatedSettlementRange
  );

  useEffect(() => {
    if (loadEventFired) return;

    analyticsTrackEvent(
      {
        action: 'loaded',
        category: 'pts',
        label,
        account_key: accountId,
        enrolled_balance: Math.round(enrolledBalance), // rounding down as BI requested an integer
        estimated_settlement_date: abbrSettlementRange
      },
      eventName
    );
    setLoadEventFired(true);
  }, [
    accountId,
    enrolledBalance,
    eventName,
    abbrSettlementRange,
    label,
    loadEventFired
  ]);
}

export function EstimatedSettlements({
  activeCoreQuestions,
  activeAlertCards,
  activeInfoCards,
  missingAccountNumberOrDate,
  nextSettlements
}) {
  const { t } = useTranslation();

  const hasEstimatedSettlements =
    Array.isArray(nextSettlements) && nextSettlements.length !== 0;
  const canRenderSettlements =
    hasEstimatedSettlements && !missingAccountNumberOrDate;

  const headingText = canRenderSettlements
    ? t('pts.estimatedDebtToNegotiate')
    : t('pts.yourSettlementSnapshotIsComingSoon');

  return (
    <>
      <PrimaryBox headingText={headingText}>
        {canRenderSettlements ? (
          <EstimatedSettlementCard debt={nextSettlements[0]} place="First">
            {activeInfoCards?.map(infoCard => (
              <InformationCard
                key={infoCard.type}
                type={infoCard.type}
                metadata={infoCard?.meta}
              />
            ))}
            {activeAlertCards?.map(alertCard => (
              <AlertCard
                key={alertCard.type}
                type={alertCard.type}
                metadata={alertCard?.meta}
              />
            ))}
            {activeCoreQuestions?.length > 0 && (
              <CoreQuestions activeCoreQuestions={activeCoreQuestions} />
            )}
          </EstimatedSettlementCard>
        ) : (
          <NoEstimatedSettlementsCard>
            <CoreQuestions activeCoreQuestions={activeCoreQuestions} />
          </NoEstimatedSettlementsCard>
        )}
      </PrimaryBox>
      {canRenderSettlements && nextSettlements[1] && (
        <Box
          data-testid="pts-next-est-debt"
          display="flex"
          flexDirection="column"
          gap="24px"
        >
          <Typography
            data-testid="pts-next-est-debt-heading"
            variant="ascendLabelLg"
            title={t('pts.nextEstDebtToNegotiate')}
            component="h3"
          >
            {t('pts.nextEstDebtToNegotiate')}
          </Typography>
          <EstimatedSettlementCard debt={nextSettlements[1]} place="Second" />
        </Box>
      )}
    </>
  );
}

function EstimatedSettlementCard({ debt, children, place }) {
  const {
    accountId,
    accountNumber,
    creditor,
    enrolledBalance,
    estimatedSettlementRange
  } = debt;

  const { t } = useTranslation();

  const eventData = {
    eventName: `${place} Settlement Component Loaded`,
    label: 'settlement_snapshot',
    accountId,
    enrolledBalance,
    estimatedSettlementRange
  };
  useEstimatedSettlementCardLoadEvent(eventData);

  const abbrSettlementRange = getAbbrMonthOnlySettlementRange(
    estimatedSettlementRange
  );

  return (
    <Card
      component="section"
      data-testid="pts-tradeline-card"
      sx={{ borderRadius: '8px !important' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        className={styles.tradelineCard}
      >
        <Typography
          data-testid="pts-tradeline-acct-num"
          variant="ascendHeadingSm"
        >
          {creditor} ****{accountNumber}
        </Typography>
        {enrolledBalance && (
          <Typography
            data-testid="pts-tradeline-enrolled-balance"
            variant="ascendBodySm"
          >
            <span>{t('pts.yourEnrolledBalance')}:</span>{' '}
            <NumberFormat
              value={enrolledBalance}
              displayType="text"
              thousandSeparator={true}
              fixedDecimalScale={false}
              prefix={'$'}
              renderText={value => <span>{value}</span>}
            />
          </Typography>
        )}
        <Typography data-testid="pts-tradeline-est-date" variant="ascendBodySm">
          {t('pts.estSettlementDate')}: {abbrSettlementRange}
        </Typography>
      </Box>
      {children ?? null}
    </Card>
  );
}

// PrimaryBox is the blue box that contains the first estimated settlement and info or alert cards
function PrimaryBox({ headingText, children }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main
      }}
      data-testid="pts-primary-heading-box"
      paddingX="16px"
      paddingY="24px"
      maxWidth="335px"
      margin="8px auto 24px"
    >
      <Box marginBottom="16px">
        <Typography
          data-testid="pts-primary-heading-box-heading"
          variant="ascendLabelLg"
          component="h2"
          title={headingText}
          color={theme.palette.primary.contrastText}
        >
          {headingText}
        </Typography>
      </Box>
      {children}
    </Box>
  );
}

function NoEstimatedSettlementsCard({ children }) {
  const { t } = useTranslation();
  const eventData = {
    eventName: 'Your Settlement snapshot is coming soon! Card Loaded',
    label: 'your_settlement_snapshot_is_coming_soon'
  };
  useEstimatedSettlementCardLoadEvent(eventData);

  return (
    <Card
      data-testid="pts-no-estimated-settlements-card"
      sx={{ borderRadius: '8px !important' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        component="section"
        sx={{ marginBottom: '16px' }}
      >
        <Box marginBottom="8px">
          <MugWithHeartSVG />
        </Box>
        <Typography variant="ascendBodySm" component="p">
          {t('pts.thanksForYourPatience')}
        </Typography>
      </Box>
      {children}
    </Card>
  );
}
