import { getUserIdToken } from '../firebase';

export const validateBank = async payload => {
  try {
    const token = await getUserIdToken();
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/bankValidate`;

    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json'
      }
    });

    if (response.status === 400) {
      const text = await response.text();
      throw text;
    }

    const json = await response.json();
    if (!response.ok) {
      throw json;
    }

    return json;
  } catch (error) {
    const errObj = { ...error, type: 'validateBank' };
    throw errObj;
  }
};
