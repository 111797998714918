export const CORE_QUESTION_MAP = {
  deposit_reminder: ['how_can_i_succeed'],
  order_change: ['why_did_the_order_change']
};

export const LOGGING_PREFIX = 'PATH TO SETTLEMENT (PtS) ::';

// include all possible card types that the frontend should support, alert or info
// the backend may send a card type that is not yet supported, the app would not throw
// but an analytic event may fire if the Card components partially render
export const ALL_POSSIBLE_CARD_TYPES = [
  'deposit_reminder',
  'your_success',
  'order_change'
];
