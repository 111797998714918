import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'EduCarousal' })(theme => {
  const navButtonBase = {
    bottom: '0',
    top: 'unset !important;',
    position: 'fixed !important;',
    height: '64px !important;',
    width: '50%',
    paddingTop: '8px',
    paddingLeft: '20px',
    paddingRight: '20px',
    zIndex: '2 !important'
  };
  return {
    navButton: {
      ...navButtonBase
    },
    navButtonFull: {
      ...navButtonBase,
      width: '220px',
      left: 'inherit !important'
    },
    btn: {
      borderRadius: '50%',
      width: '48px !important',
      minWidth: '48px !important',
      height: '48px',
      padding: '0 !important',
      display: 'inline-block',
      opacity: '1 !important',
      color: '#FFF !important'
    },
    btnHolder: {
      backgroundColor: '#F7FAFF !important',
      display: 'inline-block',
      position: 'fixed',
      bottom: '0',
      height: '64px',
      width: '100%',
      zIndex: 1
    },
    btnIcon: {
      verticalAlign: 'top'
    },
    btnNudge: {
      height: '64px'
    }
  };
});
export default useStyles;
