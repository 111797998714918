import React, { useState } from 'react';
import { Box, Dialog, Divider, Typography } from '@achieve/sunbeam';
import { LoadingButton } from '@ffn/ui';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import {
  useChangePasswordModal,
  useUnifiedSignout,
  useTranslation
} from 'lib/hooks';
import { BrandLogo, getAppSubDomain } from 'shared-components';
import { requestPasswordResetEmail } from 'lib/api';

import styles from './ChangePasswordModal.module.scss';

const changePasswordAnalyticsEvent = action =>
  analyticsTrackEvent(
    {
      category: 'change_password',
      action: action,
      label: 'mobile_nav_change_password_continue'
    },
    // Uppercasing first letter for formatting
    `Change Password ${action.charAt(0).toUpperCase() + action.slice(1)}`
  );

export const ChangePasswordModal = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { signout } = useUnifiedSignout();
  const subdomain = getAppSubDomain();
  const {
    showChangePasswordModal,
    closeChangePasswordModal
  } = useChangePasswordModal();

  const handleContinue = async () => {
    setLoading(true);
    changePasswordAnalyticsEvent('clicked');
    try {
      await requestPasswordResetEmail();

      changePasswordAnalyticsEvent('success');

      if (subdomain === 'achieve') {
        signout('/signin/check-email');
      } else {
        signout('/login/check-email');
      }
    } catch (error) {
      changePasswordAnalyticsEvent('failure');
      setError('Password reset failed. Please try again.');
      setLoading(false);
    }
  };

  const { t } = useTranslation();

  return (
    <Box data-testid="change-password-modal" className={styles.modalWrapper}>
      <Dialog open={showChangePasswordModal}>
        <Box className={styles.contentWrapper}>
          <Box className={styles.logo} data-testid="change-password-modal-logo">
            <BrandLogo />
          </Box>
          <Box
            className={styles.modalHeading}
            data-testid="change-password-modal-heading"
          >
            <Typography fontWeight="bold" variant="bodyS40">
              {t('nav.youLlBeSignedOutSoon')}
            </Typography>
          </Box>
          <Box
            className={styles.modalMessage}
            data-testid="change-password-modal-message"
          >
            <Typography variant="bodyS30">
              {t('nav.afterYouChangeYourPassword')}
            </Typography>
          </Box>
          <Divider />
          <Box className={styles.buttonGroupWrapper}>
            {error && (
              <Box
                className={styles.error}
                data-testid="change-password-modal-error"
              >
                <Typography variant="bodyS30">{error}</Typography>
              </Box>
            )}
            <Box className={styles.buttonWrapper}>
              <LoadingButton
                variant="secondary"
                disabled={loading}
                data-testid="change-password-modal-cancel-button"
                onClick={() => {
                  setError(null);
                  closeChangePasswordModal();
                }}
              >
                {t('nav.Cancel')}
              </LoadingButton>
            </Box>
            <Box className={styles.buttonWrapper}>
              <LoadingButton
                isLoading={loading}
                disabled={loading}
                variant="primary"
                data-testid="change-password-modal-continue-button"
                onClick={handleContinue}
              >
                {t('nav.Continue')}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ChangePasswordModal;
