import { getUserIdToken } from '../firebase';
import axios from 'axios';
import { blockIfAgent } from 'lib/utils';

export const draftModificationChange = blockIfAgent(
  async ({ allFeesData, dmfReason }) => {
    try {
      const token = await getUserIdToken();
      const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/draftModification`;

      const sfObj = {
        draftModificationType: 'change',
        allFeesData
      };

      if (dmfReason) {
        sfObj.dmfReason = dmfReason;
      }

      const response = await axios.post(endpoint, sfObj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        },
        validateStatus: status => status === 200
      });
      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        throw new Error('DATA_INSERTS_ERROR');
      }

      if (error.response.status === 404) {
        const error404 = new Error(error.response.data.error_message);
        error404.code =
          error.response.data.error_code ?? 'DRAFT_MODIFICATION_GENERAL_ERROR';
        throw error404;
      }

      throw error;
    }
  }
);
