import { omit } from 'ramda';
import { uploadToECM } from './uploadToECM';

export const collectionLetterUpload = async file => {
  const { metadata } = file;

  const excludeNewAccountNumber = metadata.newAccountNumber?.includes(
    'Account number ending in'
  );

  let newAccountMetadata = {
    creditor: metadata.creditor,
    currentBalance: metadata.currentBalance,
    document_type: metadata.document_type,
    fdr_sf_debt_account_id: metadata.fdr_sf_debt_account_id,
    newCreditorName: metadata.newCreditorName,
    originalPrevCreditor: metadata.originalPrevCreditor,
    newAccountNumber: metadata.newAccountNumber
  };
  const standardMetadata = {
    creditor: metadata.creditor,
    currentBalance: metadata.currentBalance,
    document_type: metadata.document_type,
    fdr_sf_debt_account_id: metadata.fdr_sf_debt_account_id
  };
  if (excludeNewAccountNumber) {
    newAccountMetadata = omit(['newAccountNumber'], newAccountMetadata);
  }
  const finalMetadata = metadata.newCreditorName
    ? newAccountMetadata
    : standardMetadata;

  try {
    const payload = {
      file,
      attributes: JSON.stringify(finalMetadata)
    };
    return await uploadToECM(payload);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
