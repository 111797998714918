import React from 'react';
import { Box, Button, Typography } from '@achieve/sunbeam';
import { useBrand, useTranslation } from 'lib/hooks';
import { Phone, RefreshCw } from 'react-feather';
import { translateAbbrDateRange } from 'lib/utils';

/**
 * This component will be displayed when there is an error loading a user's dashboard,
 * either because we can't retrieve their data from Salesforce, or because they no longer
 * have access to the Dashboard (b/c they've graduated, terminated or rescinded > 6 mos ago).
 * @returns {JSX.Element}
 * @constructor
 */
export function LoadingError() {
  const { t } = useTranslation();
  const brand = useBrand();

  const csPhone = brand('contact.customer-service.phone');
  const phoneHours = brand('contact.customer-service.phone-hours');

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="35px"
      padding="32px 20px"
    >
      <RefreshCw size={64} />
      <Box
        width="375px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="32px"
      >
        <Typography
          variant="ascendDisplayMd"
          textAlign="center"
          width="100%"
          component="h2"
        >
          {t('bootstrapLoader.unableToLoad')}
        </Typography>
        <Typography
          color={theme => theme.palette.text.secondary}
          variant="ascendBodyMd"
          textAlign="center"
          component="p"
        >
          {t('bootstrapLoader.callUs')}
        </Typography>
        {/** CS PHONE BUTTON **/}
        <Box width="100%">
          <Button
            color="secondary"
            fullWidth
            href={`tel:${csPhone}`}
            startIcon={<Phone />}
            variant="outlined"
          >
            {csPhone}
          </Button>
        </Box>
        {/** PHONE HOURS **/}
        <Box display="flex" flexDirection="column" width="100%" gap="16px">
          <Box display="flex" justifyContent="flex-start" width="100%">
            <Typography variant="ascendLabelMd">
              {t('bootstrapLoader.hours')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            {phoneHours.map(({ dateRange, hours }) => {
              const formattedHours =
                hours.toLowerCase() === 'closed'
                  ? t('customerService.closed')
                  : hours;
              return (
                <Box
                  key={hours}
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography>{translateAbbrDateRange(dateRange)}</Typography>
                  <Typography>{formattedHours}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
