import axios from 'axios';
import getApiUrl from 'lib/utils/getApiUrl';
import { getAppSubDomain } from 'shared-components';

/**
 * Calls our `/token` endpoint to exchange an Auth0 token for a Firebase token.
 * This is foundational to the entire app, as it's how we retrieve a Firebase token
 * that is scoped to the appropriate user.
 */
export const authTokenExchange = async token => {
  const urlStem = getApiUrl();
  const subdomain = getAppSubDomain();
  const apiUrl = `${urlStem}/token`;

  try {
    const response = await axios.post(
      apiUrl,
      {},
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'x-brand': subdomain
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      }
    );
    return response.data;
  } catch (e) {
    console.error('Error exchanging Auth0 token for Firebase token');
    throw new Error({
      code: 100,
      message: 'Error exchanging Auth0 token for Firebase token',
      details: e
    });
  }
};
