import { auth } from 'lib/firebase';
import { getIdToken } from '@firebase/auth';

export const getUserIdToken = async () => {
  const currentUser = auth.currentUser;
  try {
    const token = await getIdToken(currentUser, true);
    return token;
  } catch (error) {
    console.log(`Could not get Firebase token for user...`);
    console.log(error);
  }
};

export default getUserIdToken;
