import { getUserIdToken } from 'lib/firebase';
import axios from 'axios';
import getApiUrl from 'lib/utils/getApiUrl';
import { logger } from 'shared-components';

const LOGGER_PREFIX = 'DOC UPLOAD TO ECM ::';

/***
 * Payload should be:
 * { file: File, attributes: ...file metadata collected from form as stringified JSON }
 */
export const uploadToECM = async (payload, oobCode) => {
  const urlStem = getApiUrl();

  // payloadType parameter will be deprecated after proper backend is released
  const apiUrl = `${urlStem}/document/upload`;
  const token = await getUserIdToken();

  const authHeaders = {};

  if (token) {
    authHeaders.Authorization = `Bearer ${token}`;
  } else if (oobCode) {
    authHeaders['x-oob-code'] = oobCode;
  } else {
    logger.warn(`${LOGGER_PREFIX} No auth token or OOB provided`);
  }

  try {
    const response = await axios.post(apiUrl, payload, {
      headers: {
        'Content-type': 'multipart/form-data',
        ...authHeaders
      },
      validateStatus: status => {
        return status >= 200 && status < 500;
      }
    });

    if (response.status !== 200) {
      throw new Error('Error uploading document');
    }

    return response.data;
  } catch (e) {
    logger.error(`${LOGGER_PREFIX} ${e.message}`);
    throw e;
  }
};
