import { getUserIdToken } from 'lib/firebase';
import axios from 'axios';
import { blockIfAgent } from 'lib/utils';

export const alertResolve = blockIfAgent(async payload => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/alertResolve`;
  const token = await getUserIdToken();
  try {
    const response = await axios.put(apiUrl, payload, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      validateStatus: status => {
        return status >= 200 && status < 500;
      }
    });
    if (response.status !== 200) {
      throw new Error('ALERT_RESOLVE_FAILURE');
    }
    return response.data;
  } catch (e) {
    throw e;
  }
});
