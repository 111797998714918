import { getUserIdToken } from '../firebase';
import axios from 'axios';
import { blockIfAgent } from 'lib/utils';

const settlementAuth = blockIfAgent(async ({ data }) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/alertResolve`;
  const token = await getUserIdToken();
  try {
    const response = await axios.put(apiUrl, data, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      validateStatus: status => {
        return status >= 200 && status < 500;
      }
    });
    if (response.status !== 200) {
      throw new Error('ALERT_RESOLVE_FAILURE');
    }
    return response.data;
  } catch (e) {
    throw e;
  }
});

export function rejectSettlement({
  data: { settlementId, rejectReason }
}) {
  const rejectData = {
    alertType: 'alert_reject_settlement',
    settlement_id: settlementId,
    rejection_reason: rejectReason
  };
  return settlementAuth({ data: rejectData });
}

export function acceptSettlement({ data: { settlementId } }) {
  const acceptData = {
    alertType: 'alert_ras',
    settlement_id: settlementId
  };
  return settlementAuth({ data: acceptData });
}
