import { getUserIdToken } from '../firebase';
import axios from 'axios';

export const communicationPreferences = async () => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/emailPreferences`;
  const token = await getUserIdToken();
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      validateStatus: status => {
        return status >= 200 && status < 500;
      }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export default communicationPreferences;
