import axios from 'axios';

import { getUserIdToken } from '../firebase';
import getApiUrl from 'lib/utils/getApiUrl';
import { logger } from 'shared-components';

export async function fetchProgramDetails(fdrApplicantId) {
  const urlStem = getApiUrl();
  const apiUrl = `${urlStem}/lead/${fdrApplicantId}/programDetails`;

  try {
    const token = await getUserIdToken();
    const response = await axios.get(apiUrl, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const errorInfo = {
        status: response.status,
        message: response.statusText
      };
      throw new Error(JSON.stringify(errorInfo));
    }
  } catch (error) {
    logger.error('Error fetching program details', error);
    throw error;
  }
}

// checks if user is eligible for program
export async function fetchProgramDetailsEligibility(csClientId, token) {
  const urlStem = getApiUrl();
  const apiUrl = `${urlStem}/user/${csClientId}/cfosEligible`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const errorInfo = {
        status: response.status,
        message: response.statusText
      };
      throw new Error(JSON.stringify(errorInfo));
    }
  } catch (error) {
    logger.error('Error fetching program details eligibility', error);
    throw error;
  }
}
