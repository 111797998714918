import { format } from 'date-fns/fp';
import { blockIfAgent, USD } from 'lib/utils';
import { getUserIdToken } from '../firebase';

export const sendGeneralCsRequest = blockIfAgent(async params => {
  try {
    const token = await getUserIdToken();
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/generalCsRequest`;
    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    if (response.ok === false) {
      const error = new Error();
      error.code = 'EMAIL_REQUEST_FAILED';
      error.message = response.statusText || 'Email request failed.';
      throw error;
    }
  } catch (error) {
    throw error;
  }
});

export const sendOneTimeGCSemail = async ({
  depositDate,
  amount,
  dmfReason,
  user
}) => {
  const subject = 'Dashboard Request to Make an Additional Deposit';
  const { firstName = '', lastName = '', email = '' } = user;
  const message = `Dashboard "Additional One-Time Deposit" request from user: ${firstName} ${lastName} (${email.toLowerCase()}) \n
		Deposit Amount: $${Number(amount).toFixed(2)}
		Deposit Date: ${format('M/d/yyyy')(depositDate)}
		Deposit Reason: ${dmfReason}
		\n
		This client is a GCS banking customer.`;

  return await sendGeneralCsRequest({ subject, message });
};

export async function sendGCSIncreaseDepositRequestEmail({ client, values }) {
  const { firstName, lastName, clientEmail, draftType } = client;
  const { increaseAmount, startDate, endDate } = values;
  const subject = 'Dashboard What if deposit analysis Request';
  const message = `Dashboard "What If Deposit Analysis" request from user: ${firstName} ${lastName} (${clientEmail.toLowerCase()}) \n
		Increase Program Deposit ‘What if’ deposit analysis request: \n
		Draft Type: ${draftType} \n
		Amount INCREASE to: ${USD.format(increaseAmount)} \n
		Effective Date: ${format('MMM yyyy', startDate)} \n
		Effective Until: ${format('MMM yyyy', endDate)}
		Draft Modification Reason: \n`;

  return await sendGeneralCsRequest({ subject, message });
}
