import { getUserIdToken } from '../firebase';
import axios from 'axios';
import { blockIfAgent } from 'lib/utils';
import { format } from 'date-fns/fp';

export const draftModificationAdditional = blockIfAgent(
  async ({ values }) => {
    try {
      const token = await getUserIdToken();
      const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/draftModification`;
      const sfObj = {
        draftModificationType: 'additional',
        allFeesData: { futureDrafts: [] },
        amount: Number(values.amount).toFixed(2),
        date: format('yyyy-MM-dd')(values.depositDate),
        dmfReason: values.dmfReason
      };

      const response = await axios.post(endpoint, sfObj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        },
        validateStatus: status => status < 500
      });

      if (response?.data?.success === false && response?.data?.error) {
        throw new Error(response?.data?.error);
      } else if (response?.status !== 200) {
        throw new Error('DATA_INSERTS_ERROR');
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export default draftModificationAdditional;
