import axios from 'axios';
import { getUserIdToken } from 'lib/firebase';

export const getAppData = async () => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/bootstrap`;
  const token = await getUserIdToken();
  const { data } = await axios.get(apiUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json'
    }
  });

  return data.models;
};
