import axios from 'axios';
import store from '../../store';
import { updateModels } from 'shared-reducers/models';
import { getUserIdToken } from '../firebase';

// This function will trigger a refresh of notifications data in Firestore.
// Additionally, the caller can pass an array of model names (or [*] for all models) to refresh those specific models
export const refreshNotifications = async () => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/bootstrap`;
  const token = await getUserIdToken();
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      validateStatus: status => {
        return status >= 200 && status < 500;
      }
    });

    // Updating our Redux store here, so that alerts will clear
    store.dispatch(updateModels(response?.data?.models));
    return response.data;
  } catch (e) {
    throw e;
  }
};
