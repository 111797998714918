import { getUserIdToken } from '../firebase';
import axios from 'axios';

export const dfli = async ({ allDraftFees }) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/dfli`;
  const token = await getUserIdToken();
  try {
    const response = await axios.post(apiUrl, allDraftFees, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json'
      },
      validateStatus: status => status === 200
    });

    if (response.status !== 200) {
      const message = 'DATA_REQUESTS_ERROR';
      throw new Error(message);
    }

    return await response.data;
  } catch (error) {
    throw error;
  }
};
