import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from 'lib/firebase';
import {
  inMemoryPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithCustomToken,
  signOut
} from 'firebase/auth';
import { updateFirestoreTimestamp } from 'lib/api/updateFirestoreUserRecord';

export const authContext = createContext({});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {auth.isInitializing ? <div>Loading...</div> : children}
    </authContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export function useAuth() {
  return useContext(authContext);
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  // Will hold the firebase auth record
  const [user, setUser] = useState(null);

  // When this is initially loaded, we're initializing auth for the app.
  // It's set to false once a full login completes, or an auth failure happens.
  // This variable influences all the "loader" spinners that would prevent rendering
  // pages until it's false. Kinda important to the application!
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    if (user) {
      setIsInitializing(false);
    }
  }, [user]);

  // Making sure onAuthStateChanged is only set up once,
  // it listens and handles it's own updates
  useEffect(() => {
    // Prevent this callback from running twice in a race condition
    if (window.authListener) return;

    // Setting this to the window object to be completely certain
    // that this listener is available when we need to unsubscribe
    // from it later.
    window.authListener = onAuthStateChanged(auth, user => {
      console.log(
        `useAuth :: Auth State Changed :: ${user ? 'Logged In' : 'Logged Out'}`
      );
      if (user) {
        setUser(user);
      } else {
        setUser(null);
        setIsInitializing(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Token-based signin method, used for Agent Login urls, and DEX redirect sign-ins
   * @constructor
   * @param {string} token - Token for auth
   * @param {boolean} isUser - Boolean flag to indicate a user signin vs. an agent, added for DEX
   * @returns {object} returns the firebase auth user data
   */
  const signinWithCustomToken = async token => {
    try {
      await setPersistence(auth, inMemoryPersistence);
      const response = await signInWithCustomToken(auth, token);
      setUser(response.user);
      setIsInitializing(false);
      try {
        await updateFirestoreTimestamp(response.user.uid, 'lastUserLogin');
        return response.user;
      } catch (e) {
        throw new Error('USER_RECORD_NOT_EXISTING');
      }
    } catch (e) {
      setUser(null);
      throw e;
    }
  };

  /**
   * User signout method, called when user manually logs out,
   * or when a login error wants to clear out auth.
   *
   * Clears out all firestore data listeners, if any.
   * Shuts down the auth state listener too.
   *
   * Also, clears out all redux state for the app.
   * @constructor
   * @returns {Promise} returns the firebase signOut function promise
   */
  const signout = async (config = { redirectToForgotPass: false }) => {
    // Complete hard-core session death!
    return signOut(auth).then(() => {
      // Unsubscribe from auth state changes
      if (window.authListener) {
        window.authListener();
      }
      // Make sure the references to listeners are cleared out
      // window.dashboardListeners = undefined;
      window.authListener = undefined;

      // Clear auth user and empty out redux state
      setUser(null);
    });
  };

  // Here's where we return all usable bits for the app to access.
  return {
    user, // Will have the firebase auth record data
    isInitializing, // Boolean for knowing successful/failed auth has completed
    signout, // Method for user sign out
    signinWithCustomToken // Method for token-based sign in
  };
}
