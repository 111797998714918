import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPtsData } from '../api/fetchPtsData';
import { useAuth } from './useAuth';
import { ptsDataLoaded } from '../../store/actions';
import { logger } from 'shared-components';

export function usePathToSettlementData() {
  const { user } = useAuth();
  const { fdrApplicantId } = useSelector(state => state.userRecord);
  const dispatch = useDispatch();

  // Fetching our ptsData and putting it in Redux. This lets us access it in
  // multiple locations (ie: program progress bar, enrolled accounts and -- of course --
  // the PtS feature itself.
  useQuery({
    queryKey: 'ptsData',
    queryFn: async () => {
      return await fetchPtsData(fdrApplicantId);
    },
    enabled: !!fdrApplicantId && !!user,
    onSuccess: (data) => {
      dispatch(ptsDataLoaded(data));
    },
    onError: error => {
      logger.error('Error fetching PtS Data', error);
    },
    staleTime: Infinity
  })
  ;
}
