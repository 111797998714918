import { initializeApp } from '@firebase/app';
import { getStorage } from '@firebase/storage';
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  inMemoryPersistence
} from '@firebase/auth';

export const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};

const firebase = initializeApp(config);
const firebaseAuth = getAuth(firebase);

const { NODE_ENV } = process.env;

if (NODE_ENV !== 'test') {
  try {
    setPersistence(firebaseAuth, browserSessionPersistence);
  } catch (e) {
    setPersistence(firebaseAuth, inMemoryPersistence);
  }
}

export const auth = firebaseAuth;
export const storage = getStorage(firebase);
export * from './getUserIdToken';
export default firebase;
