import { getUserIdToken } from 'lib/firebase';
import axios from 'axios';
import getApiUrl from 'lib/utils/getApiUrl';

export const updateFirestoreUserRecord = async (
  uid,
  userRecordFieldsToUpdate
) => {
  const apiUrlStem = getApiUrl();
  const apiUrl = `${apiUrlStem}/user/${uid}`;

  const token = await getUserIdToken();
  try {
    const response = await axios.patch(apiUrl, userRecordFieldsToUpdate, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      validateStatus: status => status === 200
    });
    return response.data;
  } catch (err) {
    console.error('Error updating user record');
    console.error(err);
    throw err;
  }
};

export const updateFirestoreTimestamp = async (uid, fieldName) => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/user/${uid}/timestamp`;
  const token = await getUserIdToken();
  try {
    const response = await axios.patch(
      url,
      { fieldName },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        validateStatus: status => status === 200
      }
    );
    return response.data;
  } catch (err) {
    console.error('Error updating user record');
    console.error(err);
    throw err;
  }
};
export default updateFirestoreUserRecord;
