import {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import { getAppSubDomain } from 'shared-components';
import { useSelector } from 'react-redux';
import { useAppData, useLaunchDarkly, useQueryParam } from 'lib/hooks';
import { analyticsTrackEvent, analyticsHotjarEvent } from 'lib/utils/analytics';
import { getUserEduData, getUserRecord } from 'shared-selectors/userRecord';
import { getClient } from 'shared-selectors/client';
import updateFirestoreUserRecord from 'lib/api/updateFirestoreUserRecord';
import { useQuery } from 'react-query';
// Creating, but not exporting our context object here.
// We'll import it in the useClientEducation() hook below, which is the
// only way we want consumers to use this hook.
const EduContext = createContext();

export function EduProvider({ children }) {
  const { fetchUserRecord } = useAppData();
  const subDomain = getAppSubDomain();
  const client = useSelector(getClient);
  const userRecord = useSelector(getUserRecord);
  const eduUserData = useSelector(getUserEduData);
  const { flags, activeFlagNames } = useLaunchDarkly();
  const params = useQueryParam();

  const [eduOpen, setEduOpen] = useState(false);
  const [eduTab, setEduTab] = useState(0);
  const [lessonToDisplay, setLessonToDisplay] = useState(null);
  const [userEligibleForExperiment, setUserEligibleForExperiment] = useState(
    false
  );
  const [userShouldSeeEduModule, setUserShouldSeeEduModule] = useState(false);

  /**
   * This effect is used to check whether the user is eligible to be served the Education Module
   * As of December 2022, they must be Segment 1 users in the FDR program.
   */
  useEffect(() => {
    let isSegOne =
      client !== null &&
      client?.lifecycleMarketingSegment?.includes('Segment 1');
    // this is now a feature for FDR and AR.
    if (subDomain !== 'turnbull' && isSegOne) {
      setUserEligibleForExperiment(true);
    }
  }, [subDomain, client]);

  //TODO: create a ticket to delete this experiment from LD and code as its been turned on for 100% population and made it as feature
  /** Determine whether the experiment is enabled for the current user */
  useEffect(() => {
    if (userEligibleForExperiment) {
      setUserShouldSeeEduModule(
        flags && flags[activeFlagNames.clientEdu] === 'show-client-edu'
      );
    }
  }, [userEligibleForExperiment, flags, activeFlagNames]);

  useQuery(
    ['hotjarEventClientEdu'],
    () => analyticsHotjarEvent('recordEduSession'),
    { enabled: eduOpen, staleTime: Infinity, retry: 1 }
  );

  /**
   * Updates Client Education lesson data
   * @param {string} lesson - Lesson slug/name, i.e. 'creditors-call'
   * @param {object} data - All associated data for the lesson, i.e. { status: [1, 2], slide: [slideIndex] }
   * @returns {void} Will throw in error, has no return
   */
  const setLessonData = useCallback(
    async (lesson, data) => {
      await updateFirestoreUserRecord(userRecord?.uid, {
        clientEdu: {
          ...userRecord?.clientEdu, // ensures if optin was already there we keep it.
          lessons: {
            ...userRecord?.clientEdu?.lessons,
            [lesson]: data
          }
        }
      });
      fetchUserRecord();
    },
    [fetchUserRecord, userRecord?.uid, userRecord?.clientEdu]
  );
  /**
   * Sets client opt in flag to true
   * @returns {void} Will throw in error, has no return
   */
  const _setOptedIn = useCallback(async () => {
    await updateFirestoreUserRecord(userRecord?.uid, {
      clientEdu: {
        optedIn: true
      }
    });
    // Update the user record in Redux
    fetchUserRecord();
  }, [fetchUserRecord, userRecord?.uid]);

  const openDrawer = useCallback(
    ({ location }) => {
      // Optimistically open the drawer to the lessons list tab
      setEduOpen(true);
      setEduTab(0);

      let eventName = 'enter';
      let label;

      switch (location) {
        case 'orientation_btn':
          label = 'client_edu_v1_1_floating_button';
          break;
        case 'homepage_callout':
          label = 'client_edu_v1_1_homepage_callout';
          break;
        case 'direct_link':
          label = 'client_edu_v1_1_direct_link';
          break;
        default:
          label = 'client_edu_v1_1_unlisted';
      }

      // Only purpose of `optedIn` is for analytics tracking
      if (!eduUserData?.optedIn) {
        _setOptedIn();
        eventName = 'opt in';
      }

      try {
        analyticsTrackEvent(
          {
            category: 'edu',
            action: 'success',
            label
          },
          eventName
        );
      } catch (e) {
        analyticsTrackEvent(
          {
            category: 'edu',
            action: 'failure',
            label: 'label'
          },
          `${e} with enter or opt in`
        );
      }
    },
    [_setOptedIn, eduUserData?.optedIn]
  );

  const openLesson = useCallback(async ({ trackingId, invokedFrom }) => {
    let label;

    switch (invokedFrom) {
      case 'edu_drawer':
        label = `client_edu_v1_1_${trackingId}_edu_drawer`;
        break;
      case 'homepage_callout':
        label = `client_edu_v1_1_${trackingId}_homepage_callout`;
        break;
      case 'lesson_ack_screen':
        label = `client_edu_v1_1_${trackingId}_lesson_ack_next`;
        break;
      case 'direct_link':
        label = `client_edu_v1_1_${trackingId}_direct_link`;
        break;
      default:
        label = `client_edu_v1_1_${trackingId}_unlisted`;
    }

    // Send analytics event
    analyticsTrackEvent(
      {
        category: 'edu',
        action: 'start',
        label
      },
      'lesson opened'
    );

    setEduOpen(true);
    setEduTab(0);
    setLessonToDisplay(trackingId);
  }, []);

  /**
   * Open a lesson if a supported query param is present on the URL string
   * The `?eduModule={lessonTrackingId} param will automatically load the page
   * with the lesson content open in the drawer.
   */
  useEffect(() => {
    const moduleToLoad = params.get('eduModule');
    if (moduleToLoad) {
      openDrawer({ location: 'direct_link' });
      openLesson({ trackingId: moduleToLoad, invokedFrom: 'direct_link' });
    }
  }, [params, openDrawer, openLesson]);

  /**
   * Open a edu tab if a supported query param is present on the URL string
   * The `?eduTab={tabIndex} param will automatically load the page
   * with the lesson content open in the drawer.
   */
  useEffect(() => {
    const tabToLoad = params.get('eduTab');
    // Doing this because Number(null) is 0. So we're explicitly setting this to -1 to indicate
    // we don't want to open the drawer
    const tabNumber = tabToLoad !== null ? Number(tabToLoad) : -1;
    if (tabNumber >= 0) {
      openDrawer({ location: 'direct_link' });
      setEduTab(tabNumber);
    }
  }, [params, openDrawer, openLesson]);

  const closeDrawer = () => setEduOpen(false);
  const closeLesson = () => setLessonToDisplay(null);

  const value = {
    eduOpen,
    eduTab,
    eduUserData,
    lessonToDisplay,
    userShouldSeeEduModule,
    closeDrawer,
    closeLesson,
    openDrawer,
    openLesson,
    setEduOpen,
    setEduTab,
    setLessonData
  };
  return <EduContext.Provider value={value}>{children}</EduContext.Provider>;
}

export function useClientEducation() {
  return useContext(EduContext);
}

export default useClientEducation;
