import { blockIfAgent } from 'lib/utils';
import { bankAccountChange, validateBank } from 'lib/api';

export const newBankAccountChange = blockIfAgent(
  async ({ fieldValues, clientId, fdrApplicantId }) => {
    const validationType = fieldValues.newAccountNumber
      ? 'new-account'
      : 'renew';
    try {
      const { message } = await validateBank({
        type: validationType,
        accountNumber: fieldValues.newAccountNumber,
        routingNumber: fieldValues.newRoutingNumber,
        applicant_id: fdrApplicantId
      });

      await bankAccountChange({
        type: validationType,
        clientId,
        fieldValues,
        description: message
      });
    } catch (error) {
      throw error;
    }
  }
);
