import { useLaunchDarkly, useAuth } from 'lib/hooks';
/**
 * <LaunchDarklyFlag />
 * Responsible for fetching LD feature flags and associated data, and exposing flag data to any child component(s) via 'render' prop
 * 
 * EXAMPLE USAGE:
    <LaunchDarklyFlag flagName="Edwards-JSON-test">
      {({value, flagName, flag}) => {
        // We'll get back a falsy value from LD if the flag is not enabled
        // In that case, we don't want to render anything
        if (!value) return null;

        if (value === 'variation-1') {
          return <Variation1 />;
        }
        
        if (value === 'variation-2') {
          return <Variation2 />;
        }

        // Catch-all return so that we don't render anything if the flag is not explicitly handled
        return null;
      }}
    </LaunchDarklyFlag>

 * NOTE ON ANALYTICS:
  * A simple boolean feature flag will not trigger any special analytics tracking.
  * However, a feature flag that returns a JSON object will trigger a flag loaded event with the name of the feature flag enabled
  * as well as a fully populated 'experiements' array that includes the experiment_name and experiement_id fields for Event Horizon.
  * After that initial flag loaded event is tracked, all analytics that are fired subsequently will contain the experiments array in the 
  * Event Horizon payload.
*/

export default function LaunchDarklyFlag({ flagName, children }) {
  const { user } = useAuth();
  const { flags } = useLaunchDarkly(flagName);

  if (typeof children !== 'function') {
    throw new Error(
      'You must pass a function as a child for the <LaunchDarklyFlag /> component to properly render.'
    );
  }

  // If nothing comes back from the orchestration layer, don't call render
  if (!flags || !user) {
    return null;
  }

  const flagData = flags[flagName];

  // If there's no match for the key name in the flags object, don't even try to render anything
  if (flagData === undefined) {
    return null;
  } else {
    console.log(
      `EXPERIMENTATION :: Flag loaded for (${flagName}) with variation (${flagData})`
    );
    return children({
      // Full contents of the flag payload from LD, including the reason why this flag is 'active'
      flag: flagData,
      // Passing through the flag name that came in from props
      flagName
    });
  }
}
