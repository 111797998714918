import ipify from 'ipify2';
import { auth } from 'lib/firebase';
import { analytics, EVENT_ACTION, EVENT_ORIGIN } from 'lib/utils/eventHorizon';
import { createIdBundle } from 'lib/utils/analytics/createIdBundle';
import store from '../../store';
import { createTealiumPayload } from './analytics/createTealiumPayload';
import { getPageName } from './analytics/getPageName';
import { getCustomDimensions } from './analytics/getCustomDimensions';
import { getAppSubDomain, logger } from 'shared-components';
import { hotjar } from 'react-hotjar';
import { SITE_CONFIG } from 'constants/siteConfig';

const HOTJAR_IDENTIFY_LOGGING_PREFIX = 'HOTJAR IDENTIFY API ::';
const HOTJAR_EVENT_LOGGING_PREFIX = 'HOTJAR EVENT API ::';

// determine app FDR vs turnbull
let appNameString;
(async () => {
  const subdomain = getAppSubDomain();
  const brandConfig = SITE_CONFIG[subdomain].brandConfig;
  if (subdomain !== 'achieve') {
    appNameString = `${brandConfig['business-name']['abbr']} Client Dashboard |`;
  } else {
    appNameString = 'Achieve Resolution Dashboard |';
  }
})();

/**
 * Calls the Hotjar Identify API. Checks that Hotjar is initialized and required
 * fdrApplicantId is present
 * @param {string} fdrApplicantId - Unique client identifier required by Hotjar
 * @param {object} [userAttributes={}] - Optional user attributes for Hotjar
 * @returns {boolean} - Returns true if Hotjar identify call was successful
 * @throws Will throw an error if Hotjar is not initialized or fdrApplicantId is not present
 */
export const analyticsHotjarIdentify = (
  fdrApplicantId,
  userAttributes = {}
) => {
  initializeHotjar();
  if (hotjar.initialized() && fdrApplicantId) {
    hotjar.identify(fdrApplicantId, userAttributes);
    logger.info(
      `${HOTJAR_IDENTIFY_LOGGING_PREFIX} Hotjar Identify API called`,
      { ...userAttributes, fdrApplicantId } || null
    );
    return { success: true };
  } else {
    logger.error(
      `${HOTJAR_IDENTIFY_LOGGING_PREFIX} Hotjar not initialized or fdrApplicantId not present`
    );
    throw new Error();
  }
};

/** Public function to call HotJar API
 * HotJar API only receives an eventName, it does not store PII nor any userdata
 * just the event name. The eventName can be used to sort or trigger
 * recordings/feedback/surveys depending on the HotJar "Targeting" setup section.
 * @param {string} eventName
 */
export const analyticsHotjarEvent = eventName => {
  initializeHotjar();
  if (hotjar.initialized) {
    hotjar.event(eventName);
    logger.info(
      `${HOTJAR_EVENT_LOGGING_PREFIX} HotJar Event ${eventName} Called`
    );
    return { success: true };
  } else {
    logger.error(`${HOTJAR_EVENT_LOGGING_PREFIX} Hotjar not initialized`);
    throw new Error();
  }
};

/** Function to initialize HotJar. Tealium should initialize HotJar, but we can call this
 *  to insure
 */
export const initializeHotjar = () => {
  if (!hotjar.initialized()) {
    hotjar.initialize(1206023, 6);
  }
};

// Get the users public IP address when the file is imported
let ipAddress;
(async () => {
  try {
    // why ipify https://www.ipify.org/
    ipAddress = await ipify.ipv4();
  } catch (error) {}
})();

// Get the browser mode that's rendering the app
let displayMode = 'browser';

// Check if the app is running in a PWA on Android || iOS
if (
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone === true
) {
  displayMode = 'standalone';
}

// Calls the analytics.js track method
export const eventHorizonTrackEvent = ({
  application_data,
  eventName = 'event_name_undefined',
  fdrApplicantId = '',
  clientId = '',
  experiments = [],
  idBundle = {}
}) => {
  if (ipAddress) {
    application_data.ipAddress = ipAddress;
  }

  application_data.display_mode = displayMode;

  // https://getanalytics.io/api/#analyticstrack
  return analytics.track(eventName, {
    event_action: application_data?.event_action ?? EVENT_ACTION.APP_EVENT,
    event_origin: EVENT_ORIGIN.CLIENT,
    page_name: getPageName(application_data?.location?.pathname),
    ids: {
      client_id: clientId,
      ...idBundle
    },
    profile: {
      fdrApplicantId: fdrApplicantId
    },
    experiments,
    application_data
  });
};

// Calls the analytics.js page method
export const eventHorizonTrackPage = ({
  application_data,
  fdrApplicantId = '',
  clientId = '',
  experiments = [],
  idBundle = {}
}) => {
  // https://v5.reactrouter.com/web/api/location
  // https://getanalytics.io/api/#analyticspage
  if (ipAddress) {
    application_data.ipAddress = ipAddress;
  }

  application_data.display_mode = displayMode;

  const analyticsPayload = {
    event_action: EVENT_ACTION.PAGE_VIEW,
    event_origin: EVENT_ORIGIN.CLIENT,
    page_name: `${appNameString} ${getPageName(
      application_data?.location?.pathname
    )}`,
    ids: {
      client_id: clientId,
      ...idBundle
    },
    profile: {
      fdrApplicantId: fdrApplicantId
    },
    application_data,
    experiments
  };

  return analytics.page(analyticsPayload);
};

export function analyticsTrackEvent(data, eventName) {
  return prepareAndPushEvent([eventHorizonTrackEvent], data, eventName);
}

export function analyticsTrackPage(data, eventName) {
  return prepareAndPushEvent([eventHorizonTrackPage], data, eventName);
}

// Notice this is not a hook
// The use of getState instead of redux selectors allows us to keep this not a hook
// This important to the implementation because it is used in useEffect in the AppRouter
// where we only want to run the useEffect then the location changes
// and not when the data from the redux selectors change.
// It not being a hook also simplifies the use of the async method call on the auth object
// getState() is perfect for this because we want simple synchronous data access
const prepareAndPushEvent = async (functions, data = {}, eventName) => {
  const state = store.getState();
  const client = state?.models?.client ?? {};
  const suipOpportunity = state?.models?.suipOpportunity ?? {};
  const userRecord = state?.userRecord ?? {};
  const settlements = state?.models?.settlements ?? [];
  const fdrApplicantId = userRecord?.fdrApplicantId ?? '';
  const csClientId = userRecord?.csClientId ?? '';
  const suipOpportunityId = userRecord?.suipOpportunityId ?? '';
  const clientId = csClientId || '';
  const subdomain = getAppSubDomain();

  // subApp is defined as for 'onboarding' for all onboarding users
  // dashboard users may not have a defined subApp, so defaults to 'dashboard' just in case
  const subApp = userRecord?.subApp || 'dashboard';

  const idBundle = createIdBundle(subdomain);
  // Reading experimentation data from sessionStorage
  const experimentsFromCache = JSON.parse(
    sessionStorage.getItem('experiments')
  );

  // login success event passes form email inside data object. for other events
  // email is from userRecord
  if (!data.email_address) {
    data.email_address = userRecord?.email || '';
  }

  // when tracking events (not pages)
  // there was a race condition where by the time the
  // callback function ran and the pathname was used.
  // since the pathname was a reference the value would
  // get updated by the time it was used
  // spread to copy object
  const location = { ...window.location };

  const idTokenResult =
    (await auth?.currentUser?.getIdTokenResult()) ?? undefined;

  const customDimensions = getCustomDimensions({
    client,
    suipOpportunity,
    userRecord,
    settlements,
    idTokenResult
  });

  let application_data = {
    event: 'eventTracking',
    suip_opportunity_id: suipOpportunityId,
    sub_app: subApp,
    ...customDimensions,
    ...data,
    location
  };

  const tealium_data = createTealiumPayload(application_data, appNameString);

  application_data = {
    ...application_data,
    ...tealium_data
  };

  return Promise.all(
    functions.map(fn =>
      fn({
        application_data,
        eventName,
        experiments: experimentsFromCache ?? [],
        fdrApplicantId,
        clientId,
        idBundle
      })
    )
  );
};
