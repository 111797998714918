import { getUserIdToken } from '../firebase';
import axios from 'axios';
import { blockIfAgent } from 'lib/utils';

export const updateCommunicationPreferences = blockIfAgent(
  async ({ preferences }) => {
    const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/emailPreferences`;
    const token = await getUserIdToken();
    try {
      const response = await axios.put(apiUrl, preferences, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      });
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export default updateCommunicationPreferences;
