import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth, useBrand, useTermsLink } from 'lib/hooks';
import { css } from 'styled-components/macro';
import { Only, useResponsiveQuery } from 'atomic-layout';
import { FacebookWithCircle } from '@styled-icons/entypo-social/FacebookWithCircle';
import { TwitterWithCircle } from '@styled-icons/entypo-social/TwitterWithCircle';
import { YoutubeWithCircle } from '@styled-icons/entypo-social/YoutubeWithCircle';
import { LinkedinWithCircle } from '@styled-icons/entypo-social/LinkedinWithCircle';
import {
  Footer as FDRFooter,
  FooterItem,
  FooterSeparator,
  Link,
  Column,
  Row,
  Container,
  Image,
  Icon
} from '@ffn/ui';
import { WebsiteFeedback } from '@ffn/icons';
import { FeatureFlag } from 'shared-components';
import parse from 'url-parse';
import { useTranslation } from 'lib/hooks';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { EVENT_ACTION } from 'lib/utils/eventHorizon';

import { Phone } from 'react-feather';

export function Footer() {
  css(``); // Disables warning about css import not being used when it is below...
  const inlineRange = useResponsiveQuery({ from: 'md' });
  const largeRange = useResponsiveQuery({ to: 'xl' });
  const brand = useBrand();
  const { t } = useTranslation();
  const { user, userAccount } = useAuth();
  const isLoggedIn = user !== null;
  const now = new Date();
  const currentYear = now.getFullYear();
  const domain = document?.domain ?? '';
  const termsLink = useTermsLink();

  const openWebsiteFeedback = () => {
    console.log('Open Feedback Modal');
  };

  return (
    <FDRFooter>
      <Container>
        {isLoggedIn && (
          <>
            <Only to="md">
              <Row>
                <Column
                  cols={12}
                  css={`
                    text-align: center;
                  `}
                >
                  <Link
                    as={NavLink}
                    to="/"
                    onClick={() =>
                      analyticsTrackEvent(
                        {
                          nav_link_section: 'Footer',
                          event_action: EVENT_ACTION.LINK_CLICK,
                          click_type: 'Link Click',
                          click_id: 'Homepage',
                          click_url: 'https://' + domain,
                          clickText: 'Homepage',
                          track_event: 'global_footer'
                        },
                        'Footer: Homepage Link Click'
                      )
                    }
                  >
                    <Image src={brand('logo.alt-white')} width="97" />
                  </Link>
                  <FooterSeparator />
                </Column>
              </Row>
            </Only>
            <Row>
              <Column cols={12} colsMd={5}>
                <Row>
                  <Column cols={12} colsMd={4}>
                    <FooterItem>
                      <Link
                        as={NavLink}
                        to="/"
                        onClick={() =>
                          analyticsTrackEvent(
                            {
                              nav_link_section: 'Footer',
                              event_action: EVENT_ACTION.LINK_CLICK,
                              click_type: 'Link Click',
                              click_id: 'Homepage',
                              click_url: 'https://' + domain,
                              clickText: 'Homepage',
                              track_event: 'global_footer'
                            },
                            'Footer: Homepage Link Click'
                          )
                        }
                      >
                        {t('nav.home')}
                      </Link>
                    </FooterItem>
                    {userAccount?.subApp !== 'onboarding' && (
                      <>
                        <FeatureFlag pathKey="/enrolled-debt">
                          <FooterItem>
                            <Link
                              as={NavLink}
                              to="/enrolled-debt"
                              onClick={() =>
                                analyticsTrackEvent(
                                  {
                                    nav_link_section: 'Footer',
                                    event_action: EVENT_ACTION.LINK_CLICK,
                                    click_type: 'Link Click',
                                    click_id: 'Enrolled Debt',
                                    click_url:
                                      'https://' + domain + '/enrolled-debt',
                                    clickText: 'Enrolled Debt',

                                    track_event: 'global_footer'
                                  },
                                  'Footer: Enrolled Debt Link Click'
                                )
                              }
                            >
                              {t('nav.enrolledDebt')}
                            </Link>
                          </FooterItem>
                        </FeatureFlag>
                        <FeatureFlag pathKey="/dedicated-account">
                          <FooterItem>
                            <Link
                              as={NavLink}
                              to="/dedicated-account"
                              onClick={() =>
                                analyticsTrackEvent(
                                  {
                                    nav_link_section: 'Footer',
                                    event_action: EVENT_ACTION.LINK_CLICK,
                                    click_type: 'Link Click',
                                    click_id: 'Dedicated Account',
                                    click_url:
                                      'https://' +
                                      domain +
                                      '/dedicated-account',
                                    clickText: 'Dedicated Account',
                                    track_event: 'global_footer'
                                  },
                                  'Footer: Dedicated Account Link Click'
                                )
                              }
                            >
                              {t('nav.dedicatedAccount')}
                            </Link>
                          </FooterItem>
                        </FeatureFlag>
                      </>
                    )}
                  </Column>
                  <Column cols={12} colsMd={8}>
                    {userAccount?.subApp !== 'onboarding' && (
                      <>
                        <FeatureFlag pathKey="/program-resources">
                          <FooterItem>
                            <Link
                              as={NavLink}
                              to="/program-resources"
                              onClick={() =>
                                analyticsTrackEvent(
                                  {
                                    nav_link_section: 'Footer',
                                    event_action: EVENT_ACTION.LINK_CLICK,
                                    click_type: 'Link Click',
                                    click_id: 'Program Resources',
                                    click_url:
                                      'https://' +
                                      domain +
                                      '/program-resources',
                                    clickText: 'Program Resources',
                                    track_event: 'global_footer'
                                  },
                                  'Footer: Program Resources Link Click'
                                )
                              }
                            >
                              {t('nav.programResources')}
                            </Link>
                          </FooterItem>
                        </FeatureFlag>
                        <FeatureFlag pathKey="/customer-service">
                          <FooterItem>
                            <Link
                              as={NavLink}
                              to="/customer-service"
                              onClick={() =>
                                analyticsTrackEvent(
                                  {
                                    nav_link_section: 'Footer',
                                    event_action: EVENT_ACTION.LINK_CLICK,
                                    click_type: 'Link Click',
                                    click_id: 'Customer Service',
                                    click_url:
                                      'https://' + domain + '/customer-service',
                                    clickText: 'Customer Service',
                                    track_event: 'global_footer'
                                  },
                                  'Footer: Customer Service Link Click'
                                )
                              }
                            >
                              {t('nav.customerService')}
                            </Link>
                          </FooterItem>
                        </FeatureFlag>
                        <FeatureFlag pathKey="/settings">
                          <FooterItem data-testid="footer-settings">
                            <Link
                              as={NavLink}
                              to="/settings"
                              onClick={() =>
                                analyticsTrackEvent(
                                  {
                                    nav_link_section: 'Footer',
                                    event_action: EVENT_ACTION.LINK_CLICK,
                                    click_type: 'Link Click',
                                    click_id: 'Settings',
                                    click_url:
                                      'https://' + domain + '/settings',
                                    clickText: 'Settings',
                                    track_event: 'global_footer'
                                  },
                                  'Footer: Settings Link Click'
                                )
                              }
                            >
                              {t('nav.settings')}
                            </Link>
                          </FooterItem>
                        </FeatureFlag>
                      </>
                    )}
                  </Column>
                </Row>
                <Only to="md">
                  <FooterSeparator />
                </Only>
              </Column>
              <Column cols={12} colsMd={4}>
                {userAccount?.subApp !== 'onboarding' && (
                  <>
                    <div>
                      <FeatureFlag pathKey="/customer-service">
                        {t('footer.askQuestionText')}
                      </FeatureFlag>{' '}
                      {t('footer.suggestionText')}
                    </div>
                    <FeatureFlag pathKey="/customer-service">
                      <FooterItem>
                        <Link
                          as={NavLink}
                          to="/customer-service"
                          onClick={() =>
                            analyticsTrackEvent(
                              {
                                nav_link_section: 'Footer',
                                event_action: EVENT_ACTION.LINK_CLICK,
                                click_type: 'Link Click',
                                click_id: 'Customer Service',
                                click_url:
                                  'https://' + domain + '/customer-service',
                                clickText: 'Customer Service',
                                track_event: 'global_footer'
                              },
                              'Footer: Customer Service Link Click'
                            )
                          }
                        >
                          <div
                            css={`
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                            `}
                          >
                            <Icon icon={Phone} size={18} />
                            <span
                              css={`
                                font-weight: 400;
                                font-size: 14px;
                                margin-left: 10px;
                                vertical-align: middle;
                              `}
                            >
                              {t('footer.contactUs')}
                            </span>
                          </div>
                        </Link>
                      </FooterItem>
                    </FeatureFlag>
                  </>
                )}
                <FeatureFlag flagKey="footer.website-feedback">
                  <FooterItem>
                    <Link
                      css={`
                        font-weight: 400;
                      `}
                      onClick={() => {
                        analyticsTrackEvent(
                          {
                            nav_link_section: 'Footer',
                            event_action: EVENT_ACTION.LINK_CLICK,
                            click_type: 'Link click',
                            click_id: 'Website Feedback',
                            click_url:
                              'https://' + domain + '/website-feedback',
                            clickText: 'Website Feedback',
                            track_event: 'global_footer'
                          },
                          'Footer: Website Feedback Link Click'
                        );
                        openWebsiteFeedback();
                      }}
                    >
                      <Icon icon={WebsiteFeedback} size={18} />
                      <span
                        css={`
                          font-weight: 400;
                          font-size: 14px;
                          margin-left: 10px;
                          vertical-align: middle;
                        `}
                      >
                        Website Feedback
                      </span>
                    </Link>
                  </FooterItem>
                </FeatureFlag>
              </Column>
              <Only from="md">
                <Column
                  cols={12}
                  colsMd={3}
                  css={`
                    text-align: right;
                    padding-top: 15px;
                  `}
                >
                  <Link
                    as={NavLink}
                    to="/"
                    onClick={() =>
                      analyticsTrackEvent(
                        {
                          nav_link_section: 'Footer',
                          event_action: EVENT_ACTION.LINK_CLICK,
                          click_type: 'Link Click',
                          click_id: 'Homepage',
                          click_url: 'https://' + domain,
                          clickText: 'Homepage',
                          track_event: 'global_footer'
                        },
                        'Footer: Homepage Link Click'
                      )
                    }
                  >
                    <Image
                      src={brand('logo.alt-white')}
                      width="97"
                      css={`
                        margin-right: 15px;
                      `}
                    />
                  </Link>
                </Column>
              </Only>
            </Row>
            <FooterSeparator />
          </>
        )}
        <Row>
          <Column cols={12} colsLg={6}>
            <FeatureFlag pathKey="/about">
              <FooterItem inline={inlineRange} data-testid="footer-about">
                <Link
                  as={NavLink}
                  to="/about"
                  onClick={() =>
                    analyticsTrackEvent(
                      {
                        nav_link_section: 'Footer',
                        event_action: EVENT_ACTION.LINK_CLICK,
                        click_type: 'Link Click',
                        click_id: 'About Us',
                        click_url: 'https://' + domain + '/about',
                        clickText: 'About Us',
                        track_event: 'global_footer'
                      },
                      'Footer: About Us Link Click'
                    )
                  }
                >
                  {t('footer.aboutUs')}
                </Link>
              </FooterItem>
            </FeatureFlag>
            <FooterItem inline={inlineRange} separator={inlineRange}>
              <Link
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      nav_link_section: 'Footer',
                      event_action: EVENT_ACTION.LINK_CLICK,
                      click_type: 'Link Click',
                      click_id: 'Terms of Service',
                      click_url: termsLink,
                      clickText: 'Terms of Service',
                      track_event: 'global_footer'
                    },
                    'Footer: Terms of Service Link Click'
                  )
                }
                href={termsLink}
                target="_blank"
              >
                {t('footer.termsOfService')}
              </Link>
            </FooterItem>
            <FooterItem inline={inlineRange} separator={inlineRange}>
              <Link
                href={brand('site.privacy-url')}
                target="_blank"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      nav_link_section: 'Footer',
                      event_action: EVENT_ACTION.LINK_CLICK,
                      click_type: 'Link Click',
                      click_id: 'Privacy Policy',
                      click_url: 'https://' + domain + '/privacy-policy',
                      clickText: 'Privacy Policy',
                      track_event: 'global_footer'
                    },
                    'Footer: Privacy Policy Link Click'
                  )
                }
              >
                {t('footer.privacyPolicy')}
              </Link>
            </FooterItem>
            <FeatureFlag pathKey="/disclosures">
              <FooterItem inline={inlineRange} separator={inlineRange}>
                <Link
                  as={NavLink}
                  to="/disclosures"
                  onClick={() =>
                    analyticsTrackEvent(
                      {
                        nav_link_section: 'Footer',
                        event_action: EVENT_ACTION.LINK_CLICK,
                        click_type: 'Link Click',
                        click_id: 'Disclosures',
                        click_url: 'https://' + domain + '/disclosures',
                        clickText: 'Disclosures',
                        track_event: 'global_footer'
                      },
                      'Footer: Disclosures Link Click'
                    )
                  }
                >
                  {t('footer.disclosures')}
                </Link>
              </FooterItem>
            </FeatureFlag>
            {brand('site.url') && (
              <FooterItem
                inline={inlineRange}
                separator={inlineRange}
                data-testid="footer-home"
              >
                <Link
                  href={brand('site.url')}
                  target="_blank"
                  onClick={() => {
                    // click_id must be displayed text of link
                    // get based on brand
                    // subString used to remove schema and final '/'
                    let siteUrlString = String(brand('site.url'));
                    siteUrlString = siteUrlString.substring(
                      8,
                      siteUrlString.length - 1
                    );
                    String(brand('site.url')).substring(8);
                    analyticsTrackEvent(
                      {
                        nav_link_section: 'Footer',
                        event_action: EVENT_ACTION.LINK_CLICK,
                        click_type: 'Link Click',
                        click_id: `${siteUrlString}`,
                        click_url: `${brand('site.url')}`,
                        clickText: `${siteUrlString}`,
                        track_event: 'global_footer'
                      },
                      `Footer: ${siteUrlString} Link Click`
                    );
                  }}
                >
                  {parse(brand('site.url'), {}).hostname}
                </Link>
              </FooterItem>
            )}
          </Column>
          <Column
            cols={12}
            colsLg={4}
            marginTop={10}
            marginTopLg={0}
            css={`
              text-align: ${largeRange ? 'left' : 'right'};
            `}
          >
            &copy; {currentYear}. {t('footer.allRightsReserved')}
            {'. '}
            {brand('business-name.legal')}.
          </Column>
          <Column
            cols={12}
            colsLg={2}
            marginTop={10}
            marginTopLg={0}
            css={`
              text-align: ${largeRange ? 'center' : 'right'};
            `}
          >
            <Only to="lg">
              <FooterSeparator />
            </Only>
            {brand('socials.facebook') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.facebook')}
                target="_blank"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.LINK_CLICK,
                      nav_link_section: 'Footer',
                      click_type: 'Social Media Click',
                      click_id: 'Facebook',
                      click_url: `${brand('socials.facebook')}`,
                      clickText: 'Facebook',
                      track_event: 'global_footer'
                    },
                    'Footer: Facebook Link Click'
                  )
                }
              >
                <Icon icon={FacebookWithCircle} color="plain" size={30} />
              </Link>
            )}
            {brand('socials.youtube') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.youtube')}
                target="_blank"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.LINK_CLICK,
                      nav_link_section: 'Footer',
                      click_type: 'Social Media Click',
                      click_id: 'Youtube',
                      click_url: `${brand('socials.youtube')}`,
                      clickText: 'Youtube',
                      track_event: 'global_footer'
                    },
                    'Footer: Youtube Link Click'
                  )
                }
              >
                <Icon icon={YoutubeWithCircle} color="plain" size={30} />
              </Link>
            )}
            {brand('socials.twitter') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.twitter')}
                target="_blank"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.LINK_CLICK,
                      nav_link_section: 'Footer',
                      click_type: 'Social Media Click',
                      click_id: 'Twitter',
                      click_url: `${brand('socials.twitter')}`,
                      clickText: 'Twitter',
                      track_event: 'global_footer'
                    },
                    'Footer: Twitter Link Click'
                  )
                }
              >
                <Icon icon={TwitterWithCircle} color="plain" size={30} />
              </Link>
            )}
            {brand('socials.linkedin') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.linkedin')}
                target="_blank"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.LINK_CLICK,
                      nav_link_section: 'Footer',
                      click_type: 'Social Media Click',
                      click_id: 'Linkedin',
                      click_url: `${brand('socials.linkedin')}`,
                      clickText: 'Linkedin',
                      track_event: 'global_footer'
                    },
                    'Footer: Linkedin Link Click'
                  )
                }
              >
                <Icon icon={LinkedinWithCircle} color="plain" size={30} />
              </Link>
            )}
          </Column>
        </Row>
        <FooterSeparator />

        {(brand('site.cali-privacy-url') || brand('site.do-not-sell-site')) && (
          <Row>
            <Column
              cols={12}
              css={`
                text-align: center;
              `}
            >
              <strong>{t('footer.californiaResidents')}</strong>
              {brand('site.cali-privacy-url') && (
                <>
                  <br />
                  <Link
                    css={`
                      color: ${props => props.theme.colors.plain};
                    `}
                    href={brand('site.cali-privacy-url')}
                    target="_blank"
                    underline
                    onClick={() =>
                      analyticsTrackEvent(
                        {
                          event_action: EVENT_ACTION.LINK_CLICK,
                          nav_link_section: 'Footer',
                          click_type: 'Link Click',
                          click_id: 'California Privacy Notice',
                          click_url: `${brand('site.cali-privacy-url')}`,
                          clickText: 'California Privacy Notice',
                          track_event: 'global_footer'
                        },
                        'Footer: California Privacy Notice Link Click'
                      )
                    }
                  >
                    {t('footer.californiaPrivacyNotice')}
                  </Link>
                </>
              )}
              {brand('site.do-not-sell-site') && (
                <>
                  <br />
                  <Link
                    css={`
                      color: ${props => props.theme.colors.plain};
                    `}
                    href={brand('site.do-not-sell-site')}
                    target="_blank"
                    underline
                    onClick={() =>
                      analyticsTrackEvent(
                        {
                          event_action: EVENT_ACTION.LINK_CLICK,
                          nav_link_section: 'Footer',
                          click_type: 'Link Click',
                          click_id: 'Do Not Sell My Personal Information',
                          click_url: `${brand('site.do-not-sell-site')}`,
                          clickText: 'Do Not Sell My Personal Information',
                          track_event: 'global_footer'
                        },
                        'Footer: Do Not Sell My Personal Information Link Click'
                      )
                    }
                  >
                    {t('footer.doNotSellMyPersonalInformation')}
                  </Link>
                </>
              )}
            </Column>
          </Row>
        )}
      </Container>
    </FDRFooter>
  );
}

export default Footer;
